import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { FormSections } from '@model/enums/form-sections.enum';
import { ICurrentSection, ApplicationDataService } from '../../services/application-data.service';
import { AppBasicsCompleteDynamicConfig } from './basics-complete.dynamic-config';
import { forkJoin } from 'rxjs';
import { ReferralTypeService } from '@common/services/referral-type.service';
import { UnitsOfMeasurementService } from '@common/services/units-of-measurement.service';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IReferralType } from '@model/interfaces/referral-type';
import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';
import { IApplication } from '@model/interfaces/application';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAddress } from '@model/interfaces/address';
import { formatAddress } from '@mt-ng2/format-functions';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ApplicationBasicInfoService } from '../../services/application-basic-info.service';
import { CommonService } from '@common/services/common.service';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { ApplicationReviewBasicInfoDynamicControlsPartial } from '@model/partials/application-review-basic-info-partial.form-controls';
import { IApplicationValidationDTO } from '@model/interfaces/custom/application-validation-dto';
import { ApplicationService } from '../../../services/application.service';
import { ApplicationDynamicControlsPartial } from '@model/partials/application-partial.form-controls';
import { IGender } from '@model/interfaces/gender';
import { GenderService } from '../../../../recipients/outcomes/service/gender.service';

@Component({
    selector: 'basics-complete',
    templateUrl: './basics-complete.component.html',
})
export class AppBasicsCompleteComponent implements OnInit {
    @Input() basicsComplete: IApplicationBasicInfo;
    @Input() application: IApplication;
    @Input() denialReasonMapping: any;

    abstractApplicationBasicInfoControls: any;
    abstractApplicationControls: any;

    basicInfoForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    isEditing = false;
    referralTypeId: number;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: AppBasicsCompleteDynamicConfig<IApplicationBasicInfo>;

    referralTypes: IReferralType[];
    unitsOfMeasurement: IUnitsOfMeasurement[];
    heightUnits: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];

    heightUnitId: number;

    states: IStateMetaItem[];
    countries: ICountryMetaItem[];
    genders: IGender[];

    selectedCountryCode: string;

    private _basicConfigControls = [
        'CountryCode',
        'Zip',
        'Dob',
        'ApplicantPhoneNumber',
        'HasTextCommunication',
        'OptedOutOfEmails',
        'EmergencyContactName',
        'EmergencyContactNumber',
        'HeightUnitId',
        'Height',
        'WeightUnitId',
        'Weight',
        'PlaceOfBirth',
        'ReferralTypeId',
        'GenderId',
    ];

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: 'tab-preliminaryHistory',
        nextTabLabel: 'Preliminary History',
        previousTab: null,
        previousTabLabel: '',
        routerLink: null,
        sectionId: FormSections.Basicinfo,
    };

    get isMetric(): boolean {
        return this.heightUnitId === UnitsOfMeasurementEnums.Meters ? true : false;
    }

    get isInternational(): boolean {
        return this.selectedCountryCode !== 'US';
    }

    get isReferralTypeOther(): boolean {
        return this.referralTypeId === 9;
    }

    get basicConfigControls(): string[] {
        if (this.isReferralTypeOther) {
            this._basicConfigControls.push('Comment');
        } else {
            let index = this._basicConfigControls.indexOf('Comment');
            if (index > -1) {
                this._basicConfigControls.splice(index, 1);
            }
        }
        return this._basicConfigControls;
    }

    constructor(
        private appDataService: ApplicationDataService,
        private referralTypeService: ReferralTypeService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
        private notificationsService: NotificationsService,
        private applicationBasicInfoService: ApplicationBasicInfoService,
        private commonService: CommonService,
        private applicationService: ApplicationService,
        private genderService: GenderService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.referralTypeService.getItems(),
            this.unitsOfMeasurementService.getItems(),
            this.commonService.getStates(),
            this.commonService.getCountries(),
            this.genderService.getItems(),
        ]).subscribe(() => {
            this.referralTypes = this.referralTypeService.items;
            this.heightUnits = this.unitsOfMeasurementService.getHeightUnits();
            this.weightUnits = this.unitsOfMeasurementService.getWeightUnits();
            this.states = this.commonService.getStateMetaItems();
            this.countries = this.commonService.getCountryMetaItems();
            this.heightUnitId = this.basicsComplete.HeightUnitId;
            this.selectedCountryCode = this.basicsComplete.CountryCode;
            this.referralTypeId = this.basicsComplete.ReferralTypeId;
            this.genders = this.genderService.items;
            this.setConfig();
        });
    }

    setConfig(): void {
        const configControls = this.isInternational
            ? ['Address1', 'Address2', 'City', 'Province', ...this.basicConfigControls]
            : ['Address1', 'Address2', 'City', 'StateCode', ...this.basicConfigControls];
        this.formFactory = new AppBasicsCompleteDynamicConfig<IApplicationBasicInfo>(
            this.basicsComplete,
            this.heightUnits,
            this.weightUnits,
            this.referralTypes,
            this.countries,
            this.states,
            this.genders,
            configControls,
        );
        this.config = this.formFactory.getForUpdate();
        this.appDataService.currentSection.next(this.currentSection);
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.basicInfoForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractApplicationBasicInfoControls = new ApplicationReviewBasicInfoDynamicControlsPartial(this.basicsComplete, {
            countries: this.countries,
            formGroup: 'ApplicationBasicInfo',
            heights: this.heightUnits,
            referralTypes: this.referralTypes,
            states: this.states,
            weights: this.weightUnits,
            genders: this.genders,
        }).Form;
        this.abstractApplicationControls = new ApplicationDynamicControlsPartial(this.application, { formGroup: 'Waiver' }).Form;
    }

    getFormattedAddress(address: IAddress): string {
        return formatAddress(address);
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Address: this.fb.group({}),
            ApplicationBasicInfo: this.fb.group({}),
            Waiver: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (!this.isReferralTypeOther) {
            this.removeCommentControlValidator();
        }

        if (this.basicInfoForm.valid) {
            const updatedInfo = this.normalizeSubmittedData(this.basicInfoForm.value.ApplicationBasicInfo);
            const updatedApplication = Object.assign(this.application, this.basicInfoForm.value.Waiver);
            forkJoin([this.applicationBasicInfoService.update(updatedInfo), this.applicationService.update(updatedApplication)]).subscribe(
                ([appStatus]) => {
                    this.basicsComplete = updatedInfo;
                    this.application.ApplicationBasicInfo = this.basicsComplete;
                    this.setConfig();
                    this.success(appStatus);
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.basicInfoForm);
            this.error();
        }
    }

    removeCommentControlValidator(): void {
        let applicationBasicInfoForm = this.basicInfoForm.get('ApplicationBasicInfo') as FormGroup;
        let commentControl = applicationBasicInfoForm.get('Comment') as AbstractControl;
        commentControl.clearValidators();
        commentControl.setValidators([]);
        commentControl.updateValueAndValidity();
    }

    normalizeSubmittedData(value: any): IApplicationBasicInfo {
        const normalizedValues = {
            Country: null,
            CountryCode: this.selectedCountryCode,
            HeightUnitId: value.SelectedHeightUnit,
            State: null,
            StateCode: null,
            WeightUnitId: value.SelectedWeightUnit,
        };
        normalizedValues.StateCode = value.State ? this.states.find((s) => s.Id === value.State).StateCode : null;
        const basicsCopy = { ...this.basicsComplete };
        basicsCopy.Province = null;
        basicsCopy.StateCode = null;
        const basicInfo = Object.assign(basicsCopy, value, normalizedValues);
        if (this.isMetric) {
            basicInfo.Height = basicInfo.Meters;
        } else {
            basicInfo.Height = basicInfo.Feet * 12 + basicInfo.Inches;
        }
        basicInfo.PlaceOfBirth = this.countries.find((c) => c.Id === basicInfo.PlaceOfBirth).CountryCode;
        return basicInfo;
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.referralTypeId = this.abstractApplicationBasicInfoControls.ReferralTypeId;
        this.isEditing = false;
        this.enableDoubleClick();
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
        this.enableDoubleClick();
    }

    success(appStatus: IApplicationValidationDTO): void {
        this.notificationsService.success('Basic Info saved successfully.');
        if (appStatus.Denied) {
            this.notificationsService.info(`Donor has been denied. ${this.denialReasonMapping[appStatus.DenialId]}`);
        }
        this.enableDoubleClick();
        this.isEditing = false;
        this.applicationService.emitChange(this.application);
    }

    onHeightUnitChange(value): void {
        this.heightUnitId = value;
    }

    onCountryChange(value): void {
        this.selectedCountryCode = this.countries.find((c) => c.Id === value).CountryCode;
    }

    onReferralTypeChange(value: number): void {
        this.referralTypeId = value;
    }
}
