<div *ngIf="basicsComplete.Id > 0 && !isEditing">
    <div class="form-section-border">
        <h3>
            Basic Info
            <span class="pull-right" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h3>
    </div>
    <custom-ssn *ngIf="formCreated" [ssn]="basicsComplete.Ssn" [id]="basicsComplete.Id" type="app" [form]="basicInfoForm"></custom-ssn>
    <div class="display-padded">
        <b>Donor Has Acknowledged Waiver:</b>
        <span> {{ application.HasAcknowledgedWaiver ? 'Yes' : 'No' }}</span>
    </div>
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing">
    <div *ngIf="formCreated">
        <form [formGroup]="basicInfoForm" (ngSubmit)="formSubmitted()">
            <div class="form-section-border">
                <h3>Basic Info</h3>
            </div>
            <label>Donor Has Acknowledged Waiver</label>
            <dynamic-field [field]="abstractApplicationControls.HasAcknowledgedWaiver" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Address1" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Address2" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.City" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field *ngIf="!isInternational" [field]="abstractApplicationBasicInfoControls.State" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field *ngIf="isInternational" [field]="abstractApplicationBasicInfoControls.Province" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Zip" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field
                [field]="abstractApplicationBasicInfoControls.Country"
                [form]="basicInfoForm"
                (valueChanges)="onCountryChange($event)"
            ></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Dob" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.ApplicantPhoneNumber" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.HasTextCommunication" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.OptedOutOfEmails" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.EmergencyContactName" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.EmergencyContactNumber" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field
                [field]="abstractApplicationBasicInfoControls.SelectedHeightUnit"
                [form]="basicInfoForm"
                (valueChanges)="onHeightUnitChange($event)"
            ></dynamic-field>
            <div *ngIf="!isMetric">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Feet" [form]="basicInfoForm"></dynamic-field>
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Inches" [form]="basicInfoForm"></dynamic-field>
            </div>
            <dynamic-field *ngIf="isMetric" [field]="abstractApplicationBasicInfoControls.Meters" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.SelectedWeightUnit" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Weight" [form]="basicInfoForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.PlaceOfBirth" [form]="basicInfoForm"></dynamic-field>

            <dynamic-field
                [field]="abstractApplicationBasicInfoControls.ReferralTypeId"
                [form]="basicInfoForm"
                (valueChanges)="onReferralTypeChange($event)"
            ></dynamic-field>

            <dynamic-field
                [field]="abstractApplicationBasicInfoControls.Comment"
                [form]="basicInfoForm"
                [hidden]="!isReferralTypeOther"
            ></dynamic-field>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.GenderId" [form]="basicInfoForm"></dynamic-field>

            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </form>
    </div>
</div>
