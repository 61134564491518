import {
    DonorFamilyMemberHistoryDynamicControls,
    IDonorFamilyMemberHistoryDynamicControlsParameters,
} from '../form-controls/donor-family-member-history.form-controls';
import { IDonorFamilyMemberHistory } from '../interfaces/donor-family-member-history';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { convertInchesToFeetAndInches } from '@common/libraries/parse-height.library';
import { Validators } from '@angular/forms';
import { FormYesNoButtonComponent } from '@common/components/form-yes-no-button.component';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';

export interface IDonorFamilyMemberHistoryDynamicControlsParametersExtended extends IDonorFamilyMemberHistoryDynamicControlsParameters {
    countries?: ICountryMetaItem[];
}

export class DonorFamilyMemberHistoryDynamicControlsPartial extends DonorFamilyMemberHistoryDynamicControls {
    constructor(
        donorfamilymemberhistoryPartial?: IDonorFamilyMemberHistory,
        additionalParameters?: IDonorFamilyMemberHistoryDynamicControlsParametersExtended,
    ) {
        super(donorfamilymemberhistoryPartial, additionalParameters);

        (<DynamicLabel>this.View.HeightUnitId).value =
            donorfamilymemberhistoryPartial.HeightUnitId && donorfamilymemberhistoryPartial.HeightUnitId === UnitsOfMeasurementEnums.FeetAndInches
                ? 'Feet & Inches'
                : 'Meters';
        (<DynamicLabel>this.View.Height).value =
            donorfamilymemberhistoryPartial.HeightUnitId && donorfamilymemberhistoryPartial.HeightUnitId === UnitsOfMeasurementEnums.FeetAndInches
                ? convertInchesToFeetAndInches(donorfamilymemberhistoryPartial.Height)
                : donorfamilymemberhistoryPartial.Height;
        (<DynamicLabel>this.View.WeightUnitId).value =
            !donorfamilymemberhistoryPartial.WeightUnitId || donorfamilymemberhistoryPartial.WeightUnitId === 0
                ? null
                : additionalParameters.weightUnits.find((unit) => unit.Id === donorfamilymemberhistoryPartial.WeightUnitId).Name;
        (<DynamicLabel>this.View.Weight).value = donorfamilymemberhistoryPartial.Weight === null ? null : donorfamilymemberhistoryPartial.Weight;

        (<DynamicField>this.Form.Deceased).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.IsSibling).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.IsFullSibling).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.IsHalfSiblingFather).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.IsHalfSiblingMother).component = FormYesNoButtonComponent;

        (<DynamicLabel>this.View.PlaceOfBirth).value = donorfamilymemberhistoryPartial?.PlaceOfBirth
            ? additionalParameters.countries.find((c) => c.CountryCode === donorfamilymemberhistoryPartial?.PlaceOfBirth).Name
            : null;

        (<DynamicField>this.Form.PlaceOfBirth) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Place of birth',
            name: 'PlaceOfBirth',
            options: additionalParameters.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                donorfamilymemberhistoryPartial?.PlaceOfBirth && additionalParameters.countries
                    ? additionalParameters.countries.find((c) => c.CountryCode === donorfamilymemberhistoryPartial?.PlaceOfBirth).Id
                    : null,
        });

        (<DynamicField>this.Form.Feet) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Feet',
            name: 'Feet',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: donorfamilymemberhistoryPartial.Height ? Math.floor(donorfamilymemberhistoryPartial.Height / 12) : null,
        });

        (<DynamicField>this.Form.Inches) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Inches',
            name: 'Inches',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(12)],
            validators: { required: true, max: 12 },
            value: donorfamilymemberhistoryPartial.Height ? donorfamilymemberhistoryPartial.Height % 12 : null,
        });

        (<DynamicField>this.Form.Meters) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Meters',
            name: 'Meters',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Float,
                scale: 2,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: donorfamilymemberhistoryPartial.Height ? donorfamilymemberhistoryPartial.Height : null,
        });

        (<DynamicField>this.Form.SelectedHeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Height',
            name: 'SelectedHeightUnit',
            options: additionalParameters.heightUnits,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: donorfamilymemberhistoryPartial.HeightUnitId ? donorfamilymemberhistoryPartial.HeightUnitId : null,
        });

        (<DynamicField>this.Form.SelectedWeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            name: 'SelectedWeightUnit',
            options: additionalParameters.weightUnits,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: donorfamilymemberhistoryPartial.WeightUnitId ? donorfamilymemberhistoryPartial.WeightUnitId : null,
        });

        (<DynamicField>this.Form.Weight) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            name: 'Weight',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(300)],
            validators: { required: true, max: 300 },
            value: donorfamilymemberhistoryPartial.Weight ? donorfamilymemberhistoryPartial.Weight : null,
        });
    }
}
