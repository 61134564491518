import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IBirthControlType } from '@model/interfaces/birth-control-type';
import { IBodyStructure } from '@model/interfaces/body-structure';
import { IGpa } from '@model/interfaces/gpa';
import { IEducationCompletedType } from '@model/interfaces/education-completed-type';
import { IEyeColor } from '@model/interfaces/eye-color';
import { IHairTexture } from '@model/interfaces/hair-texture';
import { INaturalHairColor } from '@model/interfaces/natural-hair-color';
import { IRace } from '@model/interfaces/race';
import { ISkinType } from '@model/interfaces/skin-type';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import {
    ApplicationWhoAreYouDynamicControls,
    IApplicationWhoAreYouDynamicControlsParameters,
} from '@model/form-controls/application-who-are-you.form-controls';
import { ISeriousTraumaticEvent } from '@model/interfaces/serious-traumatic-event';
import { AdminApplicationWhoAreYouDynamicControlsPartial } from '@model/partials/admin-application-who-are-you-partial.form-controls';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';

export class WhoAreYouDynamicConfig<T extends IApplicationWhoAreYou> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private whoAreYou: T,
        private whoAreYouMetaItems?: IPersonalInformationMetaData,
        private birthControlTypes?: IBirthControlType[],
        private bodyStructures?: IBodyStructure[],
        private collegeGpas?: IGpa[],
        private educationCompletedTypes?: IEducationCompletedType[],
        private eyeColors?: IEyeColor[],
        private hairTextures?: IHairTexture[],
        private highSchoolGpas?: IGpa[],
        private naturalHairColors?: INaturalHairColor[],
        private races?: IRace[],
        private skinTypes?: ISkinType[],
        private seriousTraumaticEvents?: ISeriousTraumaticEvent[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IApplicationWhoAreYouDynamicControlsParameters = {
            birthControlTypes: birthControlTypes,
            bodyStructures: bodyStructures,
            collegeGpas: collegeGpas,
            educationCompletedTypes: educationCompletedTypes,
            eyeColors: eyeColors,
            hairTextures: hairTextures,
            highSchoolGpas: highSchoolGpas,
            naturalHairColors: naturalHairColors,
            races: races,
            seriousTraumaticEvents: seriousTraumaticEvents,
            skinTypes: skinTypes,
        };
        const dynamicControls = new AdminApplicationWhoAreYouDynamicControlsPartial(this.whoAreYouMetaItems, this.whoAreYou, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'BodyStructureId',
                'EyeColorId',
                'NaturalHairColorId',
                'HairTextureId',
                'SkinTypeId',
                'RaceId',
                'EducationCompletedTypeId',
                'HighSchoolGpaId',
                'CollegeGpaId',
                'CurrentOccupation',
                'CollegeMajor',
                'TalentsAbilities',
                'Favorites',
                'CharacterDescription',
                'WhyInterestedInBecomingEggDonor',
                'Goals',
                'ExerciseRoutine',
                'HasPlasticSurgery',
                'PlasticSurgeryTypeAndYear',
                'HasBeenVictimOfSeriousTraumaticEvent',
                'SeriousTraumaticEventId',
                'HasDepression',
                'HasMoodSwings',
                'HasAnxiety',
                'HasSchizophreniaSigns',
                'HasBeenUnderPsychiatristCare',
                'HasBeenInRehab',
                'HasFamilyBirthDefects',
                'FamilyBirthDefects',
                'BirthControlTypeId',
                'OtherBirthControlMethod',
                'BirthControlTimespan',
                'HasBeenPregnant',
                'HasGivenBirth',
                'HowManyLiveBirths',
                'NumberOfChildren',
                'HasPreviouslyDonatedEggs',
                'NumberOfEggDonations',
                'IsCurrentlyListedOnAnotherEggDonorRoster',
                'OtherEggDonorRosters',
                'OtherCommentsForTweb',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
