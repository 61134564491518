import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IApplication } from '../interfaces/application';
import { IApplicationBasicInfo } from '../interfaces/application-basic-info';
import { IApplicationFamilyHistory } from '../interfaces/application-family-history';
import { IApplicationIssue } from '../interfaces/application-issue';
import { IApplicationPreliminaryHistory } from '../interfaces/application-preliminary-history';
import { IApplicationStatus } from '../interfaces/application-status';
import { IApplicationWhoAreYou } from '../interfaces/application-who-are-you';
import { IDenialReason } from '../interfaces/denial-reason';
import { IUser } from '../interfaces/user';

export interface IApplicationDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
    applicationStatuses?: IApplicationStatus[];
    denialReasons?: IDenialReason[];
    applicationBasicInfos?: IApplicationBasicInfo[];
    applicationPreliminaryHistories?: IApplicationPreliminaryHistory[];
    applicationIssues?: IApplicationIssue[];
    applicationWhoAreYous?: IApplicationWhoAreYou[];
    applicationFamilyHistories?: IApplicationFamilyHistory[];
}

export class ApplicationDynamicControls {

    formGroup: string;
    users: IUser[];
    applicationStatuses: IApplicationStatus[];
    denialReasons: IDenialReason[];
    applicationBasicInfos: IApplicationBasicInfo[];
    applicationPreliminaryHistories: IApplicationPreliminaryHistory[];
    applicationIssues: IApplicationIssue[];
    applicationWhoAreYous: IApplicationWhoAreYou[];
    applicationFamilyHistories: IApplicationFamilyHistory[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private application?: IApplication, additionalParameters?: IApplicationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Application';
        this.users = additionalParameters && additionalParameters.users || undefined;
        this.applicationStatuses = additionalParameters && additionalParameters.applicationStatuses || undefined;
        this.denialReasons = additionalParameters && additionalParameters.denialReasons || undefined;
        this.applicationBasicInfos = additionalParameters && additionalParameters.applicationBasicInfos || undefined;
        this.applicationPreliminaryHistories = additionalParameters && additionalParameters.applicationPreliminaryHistories || undefined;
        this.applicationIssues = additionalParameters && additionalParameters.applicationIssues || undefined;
        this.applicationWhoAreYous = additionalParameters && additionalParameters.applicationWhoAreYous || undefined;
        this.applicationFamilyHistories = additionalParameters && additionalParameters.applicationFamilyHistories || undefined;

        this.Form = {
            ApplicationBasicInfoComplete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Basic Info Complete',
                name: 'ApplicationBasicInfoComplete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationBasicInfoComplete || null,
            }),
            ApplicationBasicInfoId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Basic Info',
                name: 'ApplicationBasicInfoId',
                options: this.applicationBasicInfos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationBasicInfoId || null,
            }),
            ApplicationFamilyHistoryComplete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Family History Complete',
                name: 'ApplicationFamilyHistoryComplete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationFamilyHistoryComplete || null,
            }),
            ApplicationFamilyHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Family History',
                name: 'ApplicationFamilyHistoryId',
                options: this.applicationFamilyHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationFamilyHistoryId || null,
            }),
            ApplicationIssueComplete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Issue Complete',
                name: 'ApplicationIssueComplete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationIssueComplete || null,
            }),
            ApplicationIssueId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Issue',
                name: 'ApplicationIssueId',
                options: this.applicationIssues,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationIssueId || null,
            }),
            ApplicationPreliminaryHistoryComplete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Preliminary History Complete',
                name: 'ApplicationPreliminaryHistoryComplete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationPreliminaryHistoryComplete || null,
            }),
            ApplicationPreliminaryHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Preliminary History',
                name: 'ApplicationPreliminaryHistoryId',
                options: this.applicationPreliminaryHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationPreliminaryHistoryId || null,
            }),
            ApplicationStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Status',
                name: 'ApplicationStatusId',
                options: this.applicationStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationStatusId || 1,
            }),
            ApplicationWaiverPhotosComplete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Waiver Photos Complete',
                name: 'ApplicationWaiverPhotosComplete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationWaiverPhotosComplete || null,
            }),
            ApplicationWhoAreYouComplete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Who Are You Complete',
                name: 'ApplicationWhoAreYouComplete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationWhoAreYouComplete || null,
            }),
            ApplicationWhoAreYouId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Who Are You',
                name: 'ApplicationWhoAreYouId',
                options: this.applicationWhoAreYous,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.ApplicationWhoAreYouId || null,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.hasOwnProperty('Archived') && this.application.Archived !== null ? this.application.Archived : false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.application && this.application.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.DateModified || null,
            }),
            DenialReasonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Denial Reason',
                name: 'DenialReasonId',
                options: this.denialReasons,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.DenialReasonId || null,
            }),
            DonorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor',
                name: 'DonorId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.DonorId || 100000,
            }),
            HasAcknowledgedWaiver: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Acknowledged Waiver',
                name: 'HasAcknowledgedWaiver',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.hasOwnProperty('HasAcknowledgedWaiver') && this.application.HasAcknowledgedWaiver !== null ? this.application.HasAcknowledgedWaiver : false,
            }),
            ManualDenialReason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Manual Denial Reason',
                name: 'ManualDenialReason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.application && this.application.hasOwnProperty('ManualDenialReason') && this.application.ManualDenialReason !== null ? this.application.ManualDenialReason.toString() : '',
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.application && this.application.UserId || null,
            }),
        };

        this.View = {
            ApplicationBasicInfoComplete: new DynamicLabel(
                'Application Basic Info Complete',
                this.application && this.application.ApplicationBasicInfoComplete || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ApplicationBasicInfoId: new DynamicLabel(
                'Application Basic Info',
                getMetaItemValue(this.applicationBasicInfos, this.application && this.application.hasOwnProperty('ApplicationBasicInfoId') && this.application.ApplicationBasicInfoId !== null ? this.application.ApplicationBasicInfoId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApplicationFamilyHistoryComplete: new DynamicLabel(
                'Application Family History Complete',
                this.application && this.application.ApplicationFamilyHistoryComplete || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ApplicationFamilyHistoryId: new DynamicLabel(
                'Application Family History',
                getMetaItemValue(this.applicationFamilyHistories, this.application && this.application.hasOwnProperty('ApplicationFamilyHistoryId') && this.application.ApplicationFamilyHistoryId !== null ? this.application.ApplicationFamilyHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApplicationIssueComplete: new DynamicLabel(
                'Application Issue Complete',
                this.application && this.application.ApplicationIssueComplete || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ApplicationIssueId: new DynamicLabel(
                'Application Issue',
                getMetaItemValue(this.applicationIssues, this.application && this.application.hasOwnProperty('ApplicationIssueId') && this.application.ApplicationIssueId !== null ? this.application.ApplicationIssueId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApplicationPreliminaryHistoryComplete: new DynamicLabel(
                'Application Preliminary History Complete',
                this.application && this.application.ApplicationPreliminaryHistoryComplete || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ApplicationPreliminaryHistoryId: new DynamicLabel(
                'Application Preliminary History',
                getMetaItemValue(this.applicationPreliminaryHistories, this.application && this.application.hasOwnProperty('ApplicationPreliminaryHistoryId') && this.application.ApplicationPreliminaryHistoryId !== null ? this.application.ApplicationPreliminaryHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApplicationStatusId: new DynamicLabel(
                'Application Status',
                getMetaItemValue(this.applicationStatuses, this.application && this.application.hasOwnProperty('ApplicationStatusId') && this.application.ApplicationStatusId !== null ? this.application.ApplicationStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApplicationWaiverPhotosComplete: new DynamicLabel(
                'Application Waiver Photos Complete',
                this.application && this.application.ApplicationWaiverPhotosComplete || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ApplicationWhoAreYouComplete: new DynamicLabel(
                'Application Who Are You Complete',
                this.application && this.application.ApplicationWhoAreYouComplete || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ApplicationWhoAreYouId: new DynamicLabel(
                'Application Who Are You',
                getMetaItemValue(this.applicationWhoAreYous, this.application && this.application.hasOwnProperty('ApplicationWhoAreYouId') && this.application.ApplicationWhoAreYouId !== null ? this.application.ApplicationWhoAreYouId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Archived: new DynamicLabel(
                'Archived',
                this.application && this.application.hasOwnProperty('Archived') && this.application.Archived !== null ? this.application.Archived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.application && this.application.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.application && this.application.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DenialReasonId: new DynamicLabel(
                'Denial Reason',
                getMetaItemValue(this.denialReasons, this.application && this.application.hasOwnProperty('DenialReasonId') && this.application.DenialReasonId !== null ? this.application.DenialReasonId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonorId: new DynamicLabel(
                'Donor',
                this.application && this.application.DonorId || 100000,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            HasAcknowledgedWaiver: new DynamicLabel(
                'Has Acknowledged Waiver',
                this.application && this.application.hasOwnProperty('HasAcknowledgedWaiver') && this.application.HasAcknowledgedWaiver !== null ? this.application.HasAcknowledgedWaiver : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ManualDenialReason: new DynamicLabel(
                'Manual Denial Reason',
                this.application && this.application.hasOwnProperty('ManualDenialReason') && this.application.ManualDenialReason !== null ? this.application.ManualDenialReason.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
            ),
            UserId: new DynamicLabel(
                'User',
                getMetaItemValue(this.users, this.application && this.application.hasOwnProperty('UserId') && this.application.UserId !== null ? this.application.UserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
