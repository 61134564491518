import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressContainer } from '@common/libraries/address-container.library';
import { ClinicEntityTypes } from '@model/enums/clinic-entity-types.enum';
import { ClinicContactDynamicControls } from '@model/form-controls/clinic-contact.form-controls';
import { IAddressContainer, IPhone } from '@model/interfaces/base';
import { IClinicEntity } from '@model/interfaces/clinic-entity';
import { IClinicEntityPhone } from '@model/interfaces/clinic-entity-phone';
import { IClinicEntityType } from '@model/interfaces/clinic-entity-type';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { format } from '@mt-ng2/format-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { ClinicEntityService } from '../services/clinic-entity.service';

@Component({
    selector: 'clinic-entity',
    styles: [
        `
            .header-button {
                font-size: 11px;
                margin-top: -10px;
            }
            .mr-10 {
                margin-right: 10px;
            }
        `,
    ],
    templateUrl: 'clinic-entity.component.html',
})
export class ClinicEntityComponent implements OnInit {
    @Input() clinicId: number;
    @Input() clinicEntityType: IClinicEntityType;
    @Input() canEdit: boolean;
    abstractClinicContactControls;

    clinicEntity: IClinicEntity;
    formGroup: FormGroup;
    canAdd = false;

    isEditing = false;
    address: IAddressContainer;
    phones: IPhone[];

    _allowInternationalAddresses = true;
    doubleClickIsDisabled = false;

    get isMedicalDirector(): boolean {
        return this.clinicEntityType.Id === ClinicEntityTypes.MedicalDirector;
    }

    get isLabShippingAddress(): boolean {
        return this.clinicEntityType.Id === ClinicEntityTypes.LabShippingAddress;
    }

    get isBillingAddress(): boolean {
        return this.clinicEntityType.Id === ClinicEntityTypes.BillingAddress;
    }

    get isNewEntity(): boolean {
        return this.clinicEntity?.Id === 0;
    }

    constructor(private clinicEntityService: ClinicEntityService, private fb: FormBuilder, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.clinicEntityService.getByClinicId(this.clinicId, this.clinicEntityType.Id).subscribe((entity) => {
            this.clinicEntity = entity ? entity : this.clinicEntityService.getEmptyClinicEntity(this.clinicEntityType.Id, this.clinicId);
            this.initView();
        });
    }

    initView(): void {
        this.getAddress();
        this.getPhones();
        this.setForm();
    }

    getAddress(): void {
        this.address = this.clinicEntity.Address ? AddressContainer.buildAddressContainer(this.clinicEntity.Address) : AddressContainer.getNew();
        this.clinicEntity.Address = this.address.Address;
    }

    getPhones(): void {
        this.phones = this.clinicEntity.ClinicEntityPhones;
        this.canAdd = this.canEdit;
    }

    formatAddressforView(): string {
        return this.address?.Address ? format.address(this.address.Address) : '';
    }

    formatPhoneforView(phone: IClinicEntityPhone): string {
        return phone ? (phone.Extension ? `${phone.Phone} ${format.phoneWithExt(phone.Phone, phone.Extension)}` : phone.Phone) : '';
    }

    formatContactForView(): string {
        return this.clinicEntity?.ClinicContact
            ? `${this.clinicEntity.ClinicContact.FirstName || ''} ${this.clinicEntity.ClinicContact.MiddleInitial || ''} ${
                  this.clinicEntity.ClinicContact.LastName || ''
              }`
            : '';
    }

    setForm(): void {
        this.getContactControls();
        this.formGroup = this.fb.group({
            Address: this.fb.group({}),
            ClinicContact: this.fb.group({}),
            ClinicEntityPhones: this.fb.group({}),
        });
    }

    getContactControls(): void {
        this.abstractClinicContactControls = new ClinicContactDynamicControls(this.clinicEntity.ClinicContact, { formGroup: 'ClinicEntity' });
    }

    formSubmitted(): void {
        if (this.formGroup.valid) {
            this.setEntityData(this.formGroup.value);
            this.saveClinicEntity();
        } else {
            markAllFormFieldsAsTouched(this.formGroup);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private setEntityData(value: any): void {
        this.clinicEntity.Address = Object.assign(this.clinicEntity.Address, value.Address);
        this.clinicEntity.ClinicContact = Object.assign(this.clinicEntity.ClinicContact ?? {}, value.ClinicContact);
        // An empty email is considered as an invalid email
        // To resolve this we need to send it as null
        if (this.clinicEntity.ClinicContact.Email === '') {
            this.clinicEntity.ClinicContact.Email = null;
        }
        this.clinicEntity.ClinicEntityPhones = this.getPhonesFromForm(value.ClinicEntityPhones);
        this.clinicEntity.Clinic = null;
    }

    private saveClinicEntity(): void {
        if (this.isNewEntity) {
            this.clinicEntityService
                .createClinicEntity(this.clinicEntity)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.success(answer);
                });
        } else {
            this.clinicEntityService
                .updateClinicEntity(this.clinicEntity)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success(success);
                });
        }
    }

    private success(entity: IClinicEntity): void {
        this.clinicEntity = entity;
        this.initView();
        this.cancel();
        this.notificationsService.success('Clinic saved successfully.');
    }

    cancel(): void {
        this.isEditing = false;
    }

    edit(): void {
        this.isEditing = true;
    }

    showContact(): boolean {
        return [ClinicEntityTypes.ClinicAddress, ClinicEntityTypes.LabShippingAddress, ClinicEntityTypes.MedicalDirector].includes(
            this.clinicEntityType.Id,
        );
    }
    showAddress(): boolean {
        return [ClinicEntityTypes.ClinicAddress, ClinicEntityTypes.LabShippingAddress, ClinicEntityTypes.BillingAddress].includes(
            this.clinicEntityType.Id,
        );
    }
    showPhone(): boolean {
        return [ClinicEntityTypes.ClinicAddress, ClinicEntityTypes.LabShippingAddress, ClinicEntityTypes.MedicalDirector].includes(
            this.clinicEntityType.Id,
        );
    }

    protected getPhonesFromForm(collection: any): IClinicEntityPhone[] {
        const Phones: IClinicEntityPhone[] = [];
        if (collection?.phones) {
            collection.phones.forEach((phone: IClinicEntityPhone) => {
                phone.Phone = phone.Phone.replace(/\D/g, '');
                if (phone.Phone?.length >= 10) {
                    phone.Phone = phone.Phone.substring(0, 10);
                    Phones.push(phone);
                }
            });
        }
        return Phones;
    }

    copyClinicAddress(): void {
        this.clinicEntityService.getByClinicId(this.clinicId, ClinicEntityTypes.ClinicAddress).subscribe((clinicEntity) => {
            if (!clinicEntity) {
                this.notificationsService.warning('You have not added any information for Clinic Address');
            } else {
                if (clinicEntity.Address) {
                    this.setAddressFieldValue('CountryCode', clinicEntity.Address.CountryCode);
                    this.setAddressFieldValue('Address1', clinicEntity.Address.Address1);
                    this.setAddressFieldValue('Address2', clinicEntity.Address.Address2);
                    this.setAddressFieldValue('City', clinicEntity.Address.City);
                    if (clinicEntity.Address.CountryCode.toLowerCase() === 'us') {
                        this.setAddressFieldValue('StateCode', clinicEntity.Address.StateCode);
                    } else {
                        this.setAddressFieldValue('Province', clinicEntity.Address.Province);
                    }
                    this.setAddressFieldValue('Zip', clinicEntity.Address.Zip);
                }
                if (this.clinicEntityType.Id === ClinicEntityTypes.LabShippingAddress && clinicEntity.ClinicContact) {
                    this.setContactFieldValue('FirstName', clinicEntity.ClinicContact.FirstName);
                    this.setContactFieldValue('MiddleInitial', clinicEntity.ClinicContact.MiddleInitial);
                    this.setContactFieldValue('LastName', clinicEntity.ClinicContact.LastName);
                    this.setContactFieldValue('Email', clinicEntity.ClinicContact.Email);
                    if (clinicEntity.ClinicEntityPhones) {
                        this.phones = clinicEntity.ClinicEntityPhones;
                    }
                }
            }
        });
    }

    setContactFieldValue(fieldName: string, value: any): void {
        this.formGroup.controls.ClinicContact.get(fieldName).setValue(value);
    }

    setAddressFieldValue(fieldName: string, value: any): void {
        this.formGroup.controls.Address.get(fieldName).setValue(value);
    }
}
