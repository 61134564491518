import { Component, Input, OnInit } from '@angular/core';
import { FormSections } from '@model/enums/form-sections.enum';
import { WhoAreYouDynamicConfig } from './who-are-you.dynamic-config';
import { IBirthControlType } from '@model/interfaces/birth-control-type';
import { IBodyStructure } from '@model/interfaces/body-structure';
import { IGpa } from '@model/interfaces/gpa';
import { IEducationCompletedType } from '@model/interfaces/education-completed-type';
import { IEyeColor } from '@model/interfaces/eye-color';
import { IHairTexture } from '@model/interfaces/hair-texture';
import { INaturalHairColor } from '@model/interfaces/natural-hair-color';
import { IRace } from '@model/interfaces/race';
import { ISkinType } from '@model/interfaces/skin-type';
import { IAncestry } from '@model/interfaces/ancestry';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { IApplication } from '@model/interfaces/application';
import { ICurrentSection, ApplicationDataService } from '../../services/application-data.service';
import { BirthControlTypes } from '@model/enums/birth-control-types.enum';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { ApplicationService } from '../../../services/application.service';
import { ISeriousTraumaticEvent } from '@model/interfaces/serious-traumatic-event';
import { IApplicationWhoAreYouChildren } from '@model/interfaces/application-who-are-you-children';

const CONFIG_CONTROLS = [
    'BodyStructureId',
    'EyeColorId',
    'NaturalHairColorId',
    'HairTextureId',
    'SkinTypeId',
    'RaceId',
    'EducationCompletedTypeId',
    'HighSchoolGpaId',
    'CollegeMajor',
    'CollegeGpaId',
    'CurrentOccupation',
    'TalentsAbilities',
    'Favorites',
    'CharacterDescription',
    'Goals',
    'ExerciseRoutine',
    'WhyInterestedInBecomingEggDonor',
    'HasPlasticSurgery',
    'PlasticSurgeryTypeAndYear',
    'HasBeenVictimOfSeriousTraumaticEvent',
    'SeriousTraumaticEventId',
    'HasDepression',
    'HasMoodSwings',
    'HasAnxiety',
    'HasSchizophreniaSigns',
    'HasBeenUnderPsychiatristCare',
    'HasBeenInRehab',
    'HasFamilyBirthDefects',
    'FamilyBirthDefects',
    'BirthControlTypeId',
    'OtherBirthControlMethod',
    'BirthControlTimespan',
    'HasBeenPregnant',
    'HasGivenBirth',
    'HowManyLiveBirths',
    'NumberOfChildren',
    'HasPreviouslyDonatedEggs',
    'NumberOfEggDonations',
    'IsCurrentlyListedOnAnotherEggDonorRoster',
    'OtherEggDonorRosters',
    'OtherCommentsForTweb',
];

@Component({
    selector: 'who-are-you',
    templateUrl: './who-are-you.component.html',
})
export class AppWhoAreYouComponent implements OnInit {
    @Input() whoAreYou: IApplicationWhoAreYou;
    @Input() application: IApplication;

    isEditing = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: WhoAreYouDynamicConfig<IApplicationWhoAreYou>;
    doubleClickIsDisabled = false;

    birthControlTypes?: IBirthControlType[];
    bodyStructures?: IBodyStructure[];
    collegeGpas?: IGpa[];
    educationCompletedTypes?: IEducationCompletedType[];
    eyeColors?: IEyeColor[];
    hairTextures?: IHairTexture[];
    highSchoolGpas?: IGpa[];
    naturalHairColors?: INaturalHairColor[];
    races?: IRace[];
    skinTypes?: ISkinType[];
    seriousTraumaticEvents?: ISeriousTraumaticEvent[];

    fatherAncestries: IAncestry[];
    motherAncestries: IAncestry[];

    applicationChildrenDetails: IApplicationWhoAreYouChildren[];

    whoAreYouMetaItems: IPersonalInformationMetaData;

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: 'tab-familyHistory',
        nextTabLabel: 'Family History',
        previousTab: 'tab-medicalIssues',
        previousTabLabel: 'Medical Issues',
        routerLink: null,
        sectionId: FormSections.WhoAreYou,
    };

    private configControls: string[];

    constructor(private applicationService: ApplicationDataService, private appService: ApplicationService) {}

    ngOnInit(): void {
        this.applicationService.getPersonalInformationMetaData().subscribe((metaData) => {
            this.whoAreYouMetaItems = metaData;
            this.fatherAncestries = this.whoAreYou.FatherAncestries;
            this.motherAncestries = this.whoAreYou.MotherAncestries;
            this.birthControlTypes = metaData.BirthControlTypes;
            this.bodyStructures = metaData.BodyStructures;
            this.collegeGpas = metaData.Gpas;
            this.educationCompletedTypes = metaData.EducationCompletedTypes;
            this.eyeColors = metaData.EyeColors;
            this.hairTextures = metaData.HairTextures;
            this.highSchoolGpas = metaData.Gpas;
            this.naturalHairColors = metaData.HairColors;
            this.races = metaData.Races;
            this.skinTypes = metaData.SkinTypes;
            this.seriousTraumaticEvents = metaData.SeriousTraumaticEvents;
            this.setConfig();
        });
        this.applicationChildrenDetails = [...this.whoAreYou.ApplicationWhoAreYouChildrens];
    }

    setConfig(): void {
        this.configControls = [...CONFIG_CONTROLS];
        this.setViewFields();
        this.formFactory = new WhoAreYouDynamicConfig<IApplicationWhoAreYou>(
            this.whoAreYou,
            this.whoAreYouMetaItems,
            this.birthControlTypes,
            this.bodyStructures,
            this.collegeGpas,
            this.educationCompletedTypes,
            this.eyeColors,
            this.hairTextures,
            this.highSchoolGpas,
            this.naturalHairColors,
            this.races,
            this.skinTypes,
            this.seriousTraumaticEvents,
            this.configControls,
        );
        this.config = this.formFactory.getForUpdate();
        this.applicationService.currentSection.next(this.currentSection);
    }

    setViewFields(): void {
        this.fatherAncestries = this.whoAreYou.FatherAncestries;
        this.motherAncestries = this.whoAreYou.MotherAncestries;
        if (!this.whoAreYou.HasPlasticSurgery) {
            this.findAndRemoveField('PlasticSurgeryTypeAndYear');
        }
        if (this.whoAreYou.BirthControlTypeId !== BirthControlTypes.Other) {
            this.findAndRemoveField('OtherBirthControlMethod');
        }
        if (!this.whoAreYou.HasBeenPregnant) {
            this.findAndRemoveField('HasGivenBirth');
            this.findAndRemoveField('HowManyLiveBirths');
        }
        if (!this.whoAreYou.HasGivenBirth) {
            this.findAndRemoveField('NumberOfChildren');
        }
        if (!this.whoAreYou.HasPreviouslyDonatedEggs) {
            this.findAndRemoveField('NumberOfEggDonations');
        }
        if (!this.whoAreYou.HasBeenVictimOfSeriousTraumaticEvent) {
            this.findAndRemoveField('SeriousTraumaticEventId');
        }
        if (!this.whoAreYou.HasFamilyBirthDefects) {
            this.findAndRemoveField('FamilyBirthDefects');
        }
    }

    private findAndRemoveField(name: string): void {
        let index = this.configControls.findIndex((v) => v === name);
        this.configControls.splice(index, 1);
    }

    onEditFinished(updatedValue?: IApplicationWhoAreYou): void {
        if (updatedValue) {
            this.whoAreYou = updatedValue;
            this.setConfig();
            this.isEditing = false;
        }
        this.applicationChildrenDetails = [...this.whoAreYou.ApplicationWhoAreYouChildrens];
        this.isEditing = false;
        this.appService.emitChange(this.application);
    }

    edit(): void {
        this.isEditing = true;
    }
}
