import { FormYesNoButtonComponent } from '@common/components/form-yes-no-button.component';
import {
    ApplicationPreliminaryHistoryDynamicControls,
    IApplicationPreliminaryHistoryDynamicControlsParameters,
} from '@model/form-controls/application-preliminary-history.form-controls';
import { IApplicationPreliminaryHistory } from '@model/interfaces/application-preliminary-history';
import { DynamicField } from '@mt-ng2/dynamic-form';

export class AdminApplicationPreliminaryHistoryDynamicControlsPartial extends ApplicationPreliminaryHistoryDynamicControls {
    constructor(
        applicationpreliminaryhistoryPartial?: IApplicationPreliminaryHistory,
        additionalParameters?: IApplicationPreliminaryHistoryDynamicControlsParameters,
    ) {
        super(applicationpreliminaryhistoryPartial, additionalParameters);

        (<DynamicField>this.Form.DateOfStdTreatment).value = applicationpreliminaryhistoryPartial.DateOfStdTreatment;
        (<DynamicField>this.Form.DateOfStdTreatment).placeholder = 'MM/DD/YYYY';
        (<DynamicField>this.Form.DateOfStdTreatment).label = 'What date?';
        (<DynamicField>this.Form.DateOfStdTreatment).validators = { maxlength: 250, required: true };
        (<DynamicField>this.Form.StdName).label = 'Which disease?';

        (<DynamicField>this.Form.HasStd).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasStd).labelHtml = '<label>Has STD?</label>';

        (<DynamicField>this.Form.HasHiv).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasHiv).labelHtml = '<label>Has HIV?</label>';

        (<DynamicField>this.Form.IsAdopted).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.IsAdopted).labelHtml = '<label>Is Adopted?</label>';

        (<DynamicField>this.Form.HasAdoptedParents).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasAdoptedParents).labelHtml = '<label>Has Adopted Parents?</label>';
    }
}
