import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IApplicationBasicInfo } from '../interfaces/application-basic-info';
import { IUnitsOfMeasurement } from '../interfaces/units-of-measurement';
import { IReferralType } from '../interfaces/referral-type';
import { IGender } from '../interfaces/gender';

export interface IApplicationBasicInfoDynamicControlsParameters {
    formGroup?: string;
    heights?: IUnitsOfMeasurement[];
    weights?: IUnitsOfMeasurement[];
    referralTypes?: IReferralType[];
    genders?: IGender[];
}

export class ApplicationBasicInfoDynamicControls {

    formGroup: string;
    heights: IUnitsOfMeasurement[];
    weights: IUnitsOfMeasurement[];
    referralTypes: IReferralType[];
    genders: IGender[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private applicationbasicinfo?: IApplicationBasicInfo, additionalParameters?: IApplicationBasicInfoDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ApplicationBasicInfo';
        this.heights = additionalParameters && additionalParameters.heights || undefined;
        this.weights = additionalParameters && additionalParameters.weights || undefined;
        this.referralTypes = additionalParameters && additionalParameters.referralTypes || undefined;
        this.genders = additionalParameters && additionalParameters.genders || undefined;

        this.Form = {
            Address1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address1',
                name: 'Address1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Address1') && this.applicationbasicinfo.Address1 !== null ? this.applicationbasicinfo.Address1.toString() : '',
            }),
            Address2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address2',
                name: 'Address2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Address2') && this.applicationbasicinfo.Address2 !== null ? this.applicationbasicinfo.Address2.toString() : '',
            }),
            ApplicantPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Applicant Phone Number',
                name: 'ApplicantPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(20)],
                validators: { 'maxlength': 20 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('ApplicantPhoneNumber') && this.applicationbasicinfo.ApplicantPhoneNumber !== null ? this.applicationbasicinfo.ApplicantPhoneNumber.toString() : '',
            }),
            EmergencyContactName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Emergency Contact',
                name: 'EmergencyContactName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(125)],
                validators: { 'required': true, 'maxlength': 125 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('EmergencyContactName') && this.applicationbasicinfo.EmergencyContactName !== null ? this.applicationbasicinfo.EmergencyContactName.toString() : '',
            }),
            EmergencyContactNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Emergency Contact Number',
                name: 'EmergencyContactNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.minLength(10), Validators.maxLength(20)],
                validators: { 'required': true, 'minlength': 10, 'maxlength': 20 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('EmergencyContactNumber') && this.applicationbasicinfo.EmergencyContactNumber !== null ? this.applicationbasicinfo.EmergencyContactNumber.toString() : '',
            }),
            City: new DynamicField({
                formGroup: this.formGroup,
                label: 'City',
                name: 'City',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('City') && this.applicationbasicinfo.City !== null ? this.applicationbasicinfo.City.toString() : '',
            }),
            Comment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comment',
                name: 'Comment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(100)],
                validators: { 'maxlength': 100 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Comment') && this.applicationbasicinfo.Comment !== null ? this.applicationbasicinfo.Comment.toString() : '',
            }),
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Complete') && this.applicationbasicinfo.Complete !== null ? this.applicationbasicinfo.Complete : false,
            }),
            GenderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gender',
                name: 'GenderId',
                options: this.genders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: {'required': true},
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('GenderId') && this.applicationbasicinfo.GenderId !== null ? this.applicationbasicinfo.GenderId : null,
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: false,
            }),
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country Code',
                name: 'CountryCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(2)],
                validators: { 'maxlength': 2 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('CountryCode') && this.applicationbasicinfo.CountryCode !== null ? this.applicationbasicinfo.CountryCode.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.applicationbasicinfo && this.applicationbasicinfo.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.DateModified || null,
            }),
            Dob: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dob',
                name: 'Dob',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.Dob || null,
            }),
            HasTextCommunication: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Text Communication',
                name: 'HasTextCommunication',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('HasTextCommunication') && this.applicationbasicinfo.HasTextCommunication !== null ? this.applicationbasicinfo.HasTextCommunication : false,
            }),
            OptedOutOfEmails: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor Has Opted Out of E-mails',
                name: 'OptedOutOfEmails',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('OptedOutOfEmails') && this.applicationbasicinfo.OptedOutOfEmails !== null ? this.applicationbasicinfo.OptedOutOfEmails : false,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: this.heights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.Height || null,
            }),
            HeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height Unit',
                name: 'HeightUnitId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.HeightUnitId || null,
            }),
            PlaceOfBirth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Place Of Birth',
                name: 'PlaceOfBirth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(2)],
                validators: { 'maxlength': 2 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('PlaceOfBirth') && this.applicationbasicinfo.PlaceOfBirth !== null ? this.applicationbasicinfo.PlaceOfBirth.toString() : '',
            }),
            Province: new DynamicField({
                formGroup: this.formGroup,
                label: 'Province',
                name: 'Province',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Province') && this.applicationbasicinfo.Province !== null ? this.applicationbasicinfo.Province.toString() : '',
            }),
            ReferralTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Referral Type',
                name: 'ReferralTypeId',
                options: this.referralTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.ReferralTypeId || null,
            }),
            Ssn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ssn',
                name: 'Ssn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(44)],
                validators: { 'maxlength': 44 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Ssn') && this.applicationbasicinfo.Ssn !== null ? this.applicationbasicinfo.Ssn.toString() : '',
            }),
            StateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'State Code',
                name: 'StateCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3)],
                validators: { 'maxlength': 3 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('StateCode') && this.applicationbasicinfo.StateCode !== null ? this.applicationbasicinfo.StateCode.toString() : '',
            }),
            Weight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight',
                name: 'Weight',
                options: this.weights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.Weight || null,
            }),
            WeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight Unit',
                name: 'WeightUnitId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationbasicinfo && this.applicationbasicinfo.WeightUnitId || null,
            }),
            Zip: new DynamicField({
                formGroup: this.formGroup,
                label: 'Zip',
                name: 'Zip',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(20)],
                validators: { 'maxlength': 20 },
                value: this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Zip') && this.applicationbasicinfo.Zip !== null ? this.applicationbasicinfo.Zip.toString() : '',
            }),
        };

        this.View = {
            Address1: new DynamicLabel(
                'Address1',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Address1') && this.applicationbasicinfo.Address1 !== null ? this.applicationbasicinfo.Address1.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Address2: new DynamicLabel(
                'Address2',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Address2') && this.applicationbasicinfo.Address2 !== null ? this.applicationbasicinfo.Address2.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApplicantPhoneNumber: new DynamicLabel(
                'Applicant Phone Number',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('ApplicantPhoneNumber') && this.applicationbasicinfo.ApplicantPhoneNumber !== null ? this.applicationbasicinfo.ApplicantPhoneNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            EmergencyContactName: new DynamicLabel(
                'Emergency Contact',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('EmergencyContactName') && this.applicationbasicinfo.EmergencyContactName !== null ? this.applicationbasicinfo.EmergencyContactName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            EmergencyContactNumber: new DynamicLabel(
                'Emergency Contact Number',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('EmergencyContactNumber') && this.applicationbasicinfo.EmergencyContactNumber !== null ? this.applicationbasicinfo.EmergencyContactNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            City: new DynamicLabel(
                'City',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('City') && this.applicationbasicinfo.City !== null ? this.applicationbasicinfo.City.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Comment: new DynamicLabel(
                'Comment',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Comment') && this.applicationbasicinfo.Comment !== null ? this.applicationbasicinfo.Comment.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Complete: new DynamicLabel(
                'Complete',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Complete') && this.applicationbasicinfo.Complete !== null ? this.applicationbasicinfo.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            GenderId: new DynamicLabel(
                'Gender',
                getMetaItemValue(this.genders, this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('GenderId') && this.applicationbasicinfo.GenderId !== null ? this.applicationbasicinfo.GenderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Submitted: new DynamicLabel(
                'Submitted',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Submitted') && this.applicationbasicinfo.Submitted !== null ? this.applicationbasicinfo.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CountryCode: new DynamicLabel(
                'Country Code',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('CountryCode') && this.applicationbasicinfo.CountryCode !== null ? this.applicationbasicinfo.CountryCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.applicationbasicinfo && this.applicationbasicinfo.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.applicationbasicinfo && this.applicationbasicinfo.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Dob: new DynamicLabel(
                'Dob',
                this.applicationbasicinfo && this.applicationbasicinfo.Dob || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HasTextCommunication: new DynamicLabel(
                'Has Text Communication',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('HasTextCommunication') && this.applicationbasicinfo.HasTextCommunication !== null ? this.applicationbasicinfo.HasTextCommunication : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            OptedOutOfEmails: new DynamicLabel(
                'Donor Has Opted Out of E-mails',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('OptedOutOfEmails') && this.applicationbasicinfo.OptedOutOfEmails !== null ? this.applicationbasicinfo.OptedOutOfEmails : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                getMetaItemValue(this.heights, this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Height') && this.applicationbasicinfo.Height !== null ? this.applicationbasicinfo.Height : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HeightUnitId: new DynamicLabel(
                'Height Unit',
                this.applicationbasicinfo && this.applicationbasicinfo.HeightUnitId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PlaceOfBirth: new DynamicLabel(
                'Place Of Birth',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('PlaceOfBirth') && this.applicationbasicinfo.PlaceOfBirth !== null ? this.applicationbasicinfo.PlaceOfBirth.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Province: new DynamicLabel(
                'Province',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Province') && this.applicationbasicinfo.Province !== null ? this.applicationbasicinfo.Province.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReferralTypeId: new DynamicLabel(
                'Referral Type',
                getMetaItemValue(this.referralTypes, this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('ReferralTypeId') && this.applicationbasicinfo.ReferralTypeId !== null ? this.applicationbasicinfo.ReferralTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Ssn: new DynamicLabel(
                'Ssn',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Ssn') && this.applicationbasicinfo.Ssn !== null ? this.applicationbasicinfo.Ssn.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            StateCode: new DynamicLabel(
                'State Code',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('StateCode') && this.applicationbasicinfo.StateCode !== null ? this.applicationbasicinfo.StateCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Weight: new DynamicLabel(
                'Weight',
                getMetaItemValue(this.weights, this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Weight') && this.applicationbasicinfo.Weight !== null ? this.applicationbasicinfo.Weight : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WeightUnitId: new DynamicLabel(
                'Weight Unit',
                this.applicationbasicinfo && this.applicationbasicinfo.WeightUnitId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Zip: new DynamicLabel(
                'Zip',
                this.applicationbasicinfo && this.applicationbasicinfo.hasOwnProperty('Zip') && this.applicationbasicinfo.Zip !== null ? this.applicationbasicinfo.Zip.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
