import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IApplicationWhoAreYou } from '../interfaces/application-who-are-you';
import { IBirthControlType } from '../interfaces/birth-control-type';
import { IBodyStructure } from '../interfaces/body-structure';
import { IGpa } from '../interfaces/gpa';
import { IEducationCompletedType } from '../interfaces/education-completed-type';
import { IEyeColor } from '../interfaces/eye-color';
import { IHairTexture } from '../interfaces/hair-texture';
import { INaturalHairColor } from '../interfaces/natural-hair-color';
import { IRace } from '../interfaces/race';
import { ISeriousTraumaticEvent } from '../interfaces/serious-traumatic-event';
import { ISkinType } from '../interfaces/skin-type';

export interface IApplicationWhoAreYouDynamicControlsParameters {
    formGroup?: string;
    bodyStructures?: IBodyStructure[];
    eyeColors?: IEyeColor[];
    naturalHairColors?: INaturalHairColor[];
    hairTextures?: IHairTexture[];
    skinTypes?: ISkinType[];
    races?: IRace[];
    educationCompletedTypes?: IEducationCompletedType[];
    highSchoolGpas?: IGpa[];
    collegeGpas?: IGpa[];
    birthControlTypes?: IBirthControlType[];
    seriousTraumaticEvents?: ISeriousTraumaticEvent[];
}

export class ApplicationWhoAreYouDynamicControls {

    formGroup: string;
    bodyStructures: IBodyStructure[];
    eyeColors: IEyeColor[];
    naturalHairColors: INaturalHairColor[];
    hairTextures: IHairTexture[];
    skinTypes: ISkinType[];
    races: IRace[];
    educationCompletedTypes: IEducationCompletedType[];
    highSchoolGpas: IGpa[];
    collegeGpas: IGpa[];
    birthControlTypes: IBirthControlType[];
    seriousTraumaticEvents: ISeriousTraumaticEvent[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private applicationwhoareyou?: IApplicationWhoAreYou, additionalParameters?: IApplicationWhoAreYouDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ApplicationWhoAreYou';
        this.bodyStructures = additionalParameters && additionalParameters.bodyStructures || undefined;
        this.eyeColors = additionalParameters && additionalParameters.eyeColors || undefined;
        this.naturalHairColors = additionalParameters && additionalParameters.naturalHairColors || undefined;
        this.hairTextures = additionalParameters && additionalParameters.hairTextures || undefined;
        this.skinTypes = additionalParameters && additionalParameters.skinTypes || undefined;
        this.races = additionalParameters && additionalParameters.races || undefined;
        this.educationCompletedTypes = additionalParameters && additionalParameters.educationCompletedTypes || undefined;
        this.highSchoolGpas = additionalParameters && additionalParameters.highSchoolGpas || undefined;
        this.collegeGpas = additionalParameters && additionalParameters.collegeGpas || undefined;
        this.birthControlTypes = additionalParameters && additionalParameters.birthControlTypes || undefined;
        this.seriousTraumaticEvents = additionalParameters && additionalParameters.seriousTraumaticEvents || undefined;

        this.Form = {
            BirthControlTimespan: new DynamicField({
                formGroup: this.formGroup,
                label: 'Birth Control Timespan',
                name: 'BirthControlTimespan',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('BirthControlTimespan') && this.applicationwhoareyou.BirthControlTimespan !== null ? this.applicationwhoareyou.BirthControlTimespan.toString() : '',
            }),
            BirthControlTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Birth Control Type',
                name: 'BirthControlTypeId',
                options: this.birthControlTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.BirthControlTypeId || null,
            }),
            BodyStructureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Body Structure',
                name: 'BodyStructureId',
                options: this.bodyStructures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.BodyStructureId || null,
            }),
            CharacterDescription: new DynamicField({
                formGroup: this.formGroup,
                label: 'Character Description',
                name: 'CharacterDescription',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CharacterDescription') && this.applicationwhoareyou.CharacterDescription !== null ? this.applicationwhoareyou.CharacterDescription.toString() : '',
            }),
            CollegeGpaId: new DynamicField({
                formGroup: this.formGroup,
                label: 'College Gpa',
                name: 'CollegeGpaId',
                options: this.collegeGpas,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.CollegeGpaId || null,
            }),
            CollegeMajor: new DynamicField({
                formGroup: this.formGroup,
                label: 'College Major',
                name: 'CollegeMajor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CollegeMajor') && this.applicationwhoareyou.CollegeMajor !== null ? this.applicationwhoareyou.CollegeMajor.toString() : '',
            }),
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Complete') && this.applicationwhoareyou.Complete !== null ? this.applicationwhoareyou.Complete : false,
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: false,
            }),
            CurrentOccupation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Current Occupation',
                name: 'CurrentOccupation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CurrentOccupation') && this.applicationwhoareyou.CurrentOccupation !== null ? this.applicationwhoareyou.CurrentOccupation.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.applicationwhoareyou && this.applicationwhoareyou.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.DateModified || null,
            }),
            EducationCompletedTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Education Completed Type',
                name: 'EducationCompletedTypeId',
                options: this.educationCompletedTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.EducationCompletedTypeId || null,
            }),
            ExerciseRoutine: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exercise Routine',
                name: 'ExerciseRoutine',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ExerciseRoutine') && this.applicationwhoareyou.ExerciseRoutine !== null ? this.applicationwhoareyou.ExerciseRoutine.toString() : '',
            }),
            EyeColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eye Color',
                name: 'EyeColorId',
                options: this.eyeColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.EyeColorId || null,
            }),
            FamilyBirthDefects: new DynamicField({
                formGroup: this.formGroup,
                label: 'Family Birth Defects',
                name: 'FamilyBirthDefects',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('FamilyBirthDefects') && this.applicationwhoareyou.FamilyBirthDefects !== null ? this.applicationwhoareyou.FamilyBirthDefects.toString() : '',
            }),
            Favorites: new DynamicField({
                formGroup: this.formGroup,
                label: 'Favorites',
                name: 'Favorites',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Favorites') && this.applicationwhoareyou.Favorites !== null ? this.applicationwhoareyou.Favorites.toString() : '',
            }),
            Goals: new DynamicField({
                formGroup: this.formGroup,
                label: 'Goals',
                name: 'Goals',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Goals') && this.applicationwhoareyou.Goals !== null ? this.applicationwhoareyou.Goals.toString() : '',
            }),
            HairTextureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hair Texture',
                name: 'HairTextureId',
                options: this.hairTextures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.HairTextureId || null,
            }),
            HasAnxiety: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Anxiety',
                name: 'HasAnxiety',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasAnxiety') && this.applicationwhoareyou.HasAnxiety !== null ? this.applicationwhoareyou.HasAnxiety : false,
            }),
            HasBeenInRehab: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Been In Rehab',
                name: 'HasBeenInRehab',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenInRehab') && this.applicationwhoareyou.HasBeenInRehab !== null ? this.applicationwhoareyou.HasBeenInRehab : false,
            }),
            HasBeenPregnant: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Been Pregnant',
                name: 'HasBeenPregnant',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenPregnant') && this.applicationwhoareyou.HasBeenPregnant !== null ? this.applicationwhoareyou.HasBeenPregnant : false,
            }),
            HasBeenUnderPsychiatristCare: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Been Under Psychiatrist Care',
                name: 'HasBeenUnderPsychiatristCare',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenUnderPsychiatristCare') && this.applicationwhoareyou.HasBeenUnderPsychiatristCare !== null ? this.applicationwhoareyou.HasBeenUnderPsychiatristCare : false,
            }),
            HasBeenVictimOfSeriousTraumaticEvent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Been Victim Of Serious Traumatic Event',
                name: 'HasBeenVictimOfSeriousTraumaticEvent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenVictimOfSeriousTraumaticEvent') && this.applicationwhoareyou.HasBeenVictimOfSeriousTraumaticEvent !== null ? this.applicationwhoareyou.HasBeenVictimOfSeriousTraumaticEvent : false,
            }),
            HasDepression: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Depression',
                name: 'HasDepression',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasDepression') && this.applicationwhoareyou.HasDepression !== null ? this.applicationwhoareyou.HasDepression : false,
            }),
            HasFamilyBirthDefects: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Family Birth Defects',
                name: 'HasFamilyBirthDefects',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasFamilyBirthDefects') && this.applicationwhoareyou.HasFamilyBirthDefects !== null ? this.applicationwhoareyou.HasFamilyBirthDefects : false,
            }),
            HasGivenBirth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Given Birth',
                name: 'HasGivenBirth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasGivenBirth') && this.applicationwhoareyou.HasGivenBirth !== null ? this.applicationwhoareyou.HasGivenBirth : false,
            }),
            HasMoodSwings: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Mood Swings',
                name: 'HasMoodSwings',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasMoodSwings') && this.applicationwhoareyou.HasMoodSwings !== null ? this.applicationwhoareyou.HasMoodSwings : false,
            }),
            HasPlasticSurgery: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Plastic Surgery',
                name: 'HasPlasticSurgery',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasPlasticSurgery') && this.applicationwhoareyou.HasPlasticSurgery !== null ? this.applicationwhoareyou.HasPlasticSurgery : false,
            }),
            HasPreviouslyDonatedEggs: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Previously Donated Eggs',
                name: 'HasPreviouslyDonatedEggs',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasPreviouslyDonatedEggs') && this.applicationwhoareyou.HasPreviouslyDonatedEggs !== null ? this.applicationwhoareyou.HasPreviouslyDonatedEggs : false,
            }),
            HasSchizophreniaSigns: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Schizophrenia Signs',
                name: 'HasSchizophreniaSigns',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasSchizophreniaSigns') && this.applicationwhoareyou.HasSchizophreniaSigns !== null ? this.applicationwhoareyou.HasSchizophreniaSigns : false,
            }),
            HighSchoolGpaId: new DynamicField({
                formGroup: this.formGroup,
                label: 'High School Gpa',
                name: 'HighSchoolGpaId',
                options: this.highSchoolGpas,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.HighSchoolGpaId || null,
            }),
            HowManyLiveBirths: new DynamicField({
                formGroup: this.formGroup,
                label: 'How Many Live Births',
                name: 'HowManyLiveBirths',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.HowManyLiveBirths || null,
            }),
            IsCurrentlyListedOnAnotherEggDonorRoster: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Currently Listed On Another Egg Donor Roster',
                name: 'IsCurrentlyListedOnAnotherEggDonorRoster',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('IsCurrentlyListedOnAnotherEggDonorRoster') && this.applicationwhoareyou.IsCurrentlyListedOnAnotherEggDonorRoster !== null ? this.applicationwhoareyou.IsCurrentlyListedOnAnotherEggDonorRoster : false,
            }),
            NaturalHairColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Natural Hair Color',
                name: 'NaturalHairColorId',
                options: this.naturalHairColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.NaturalHairColorId || null,
            }),
            NumberOfChildren: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Children',
                name: 'NumberOfChildren',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.NumberOfChildren || null,
            }),
            NumberOfEggDonations: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Egg Donations',
                name: 'NumberOfEggDonations',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.NumberOfEggDonations || null,
            }),
            OtherAncestryFather: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Ancestry Father',
                name: 'OtherAncestryFather',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryFather') && this.applicationwhoareyou.OtherAncestryFather !== null ? this.applicationwhoareyou.OtherAncestryFather.toString() : '',
            }),
            OtherAncestryMother: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Ancestry Mother',
                name: 'OtherAncestryMother',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryMother') && this.applicationwhoareyou.OtherAncestryMother !== null ? this.applicationwhoareyou.OtherAncestryMother.toString() : '',
            }),
            OtherBirthControlMethod: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Birth Control Method',
                name: 'OtherBirthControlMethod',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherBirthControlMethod') && this.applicationwhoareyou.OtherBirthControlMethod !== null ? this.applicationwhoareyou.OtherBirthControlMethod.toString() : '',
            }),
            OtherCommentsForTweb: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Comments For Tweb',
                name: 'OtherCommentsForTweb',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherCommentsForTweb') && this.applicationwhoareyou.OtherCommentsForTweb !== null ? this.applicationwhoareyou.OtherCommentsForTweb.toString() : '',
            }),
            OtherEggDonorRosters: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Egg Donor Rosters',
                name: 'OtherEggDonorRosters',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherEggDonorRosters') && this.applicationwhoareyou.OtherEggDonorRosters !== null ? this.applicationwhoareyou.OtherEggDonorRosters.toString() : '',
            }),
            PlasticSurgeryTypeAndYear: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plastic Surgery Type And Year',
                name: 'PlasticSurgeryTypeAndYear',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('PlasticSurgeryTypeAndYear') && this.applicationwhoareyou.PlasticSurgeryTypeAndYear !== null ? this.applicationwhoareyou.PlasticSurgeryTypeAndYear.toString() : '',
            }),
            RaceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Race',
                name: 'RaceId',
                options: this.races,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.RaceId || null,
            }),
            SeriousTraumaticEventId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Serious Traumatic Event',
                name: 'SeriousTraumaticEventId',
                options: this.seriousTraumaticEvents,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.SeriousTraumaticEventId || null,
            }),
            SkinTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skin Type',
                name: 'SkinTypeId',
                options: this.skinTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationwhoareyou && this.applicationwhoareyou.SkinTypeId || null,
            }),
            TalentsAbilities: new DynamicField({
                formGroup: this.formGroup,
                label: 'Talents Abilities',
                name: 'TalentsAbilities',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3500) ],
                validators: { 'maxlength': 3500 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('TalentsAbilities') && this.applicationwhoareyou.TalentsAbilities !== null ? this.applicationwhoareyou.TalentsAbilities.toString() : '',
            }),
            WhyInterestedInBecomingEggDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Why Interested In Becoming Egg Donor',
                name: 'WhyInterestedInBecomingEggDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('WhyInterestedInBecomingEggDonor') && this.applicationwhoareyou.WhyInterestedInBecomingEggDonor !== null ? this.applicationwhoareyou.WhyInterestedInBecomingEggDonor.toString() : '',
            }),
        };

        this.View = {
            BirthControlTimespan: new DynamicLabel(
                'Birth Control Timespan',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('BirthControlTimespan') && this.applicationwhoareyou.BirthControlTimespan !== null ? this.applicationwhoareyou.BirthControlTimespan.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            BirthControlTypeId: new DynamicLabel(
                'Birth Control Type',
                getMetaItemValue(this.birthControlTypes, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('BirthControlTypeId') && this.applicationwhoareyou.BirthControlTypeId !== null ? this.applicationwhoareyou.BirthControlTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            BodyStructureId: new DynamicLabel(
                'Body Structure',
                getMetaItemValue(this.bodyStructures, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('BodyStructureId') && this.applicationwhoareyou.BodyStructureId !== null ? this.applicationwhoareyou.BodyStructureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CharacterDescription: new DynamicLabel(
                'Character Description',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CharacterDescription') && this.applicationwhoareyou.CharacterDescription !== null ? this.applicationwhoareyou.CharacterDescription.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CollegeGpaId: new DynamicLabel(
                'College Gpa',
                getMetaItemValue(this.collegeGpas, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CollegeGpaId') && this.applicationwhoareyou.CollegeGpaId !== null ? this.applicationwhoareyou.CollegeGpaId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CollegeMajor: new DynamicLabel(
                'College Major',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CollegeMajor') && this.applicationwhoareyou.CollegeMajor !== null ? this.applicationwhoareyou.CollegeMajor.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Complete: new DynamicLabel(
                'Complete',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Complete') && this.applicationwhoareyou.Complete !== null ? this.applicationwhoareyou.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Submitted: new DynamicLabel(
                'Submitted',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Submitted') && this.applicationwhoareyou.Submitted !== null ? this.applicationwhoareyou.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CurrentOccupation: new DynamicLabel(
                'Current Occupation',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CurrentOccupation') && this.applicationwhoareyou.CurrentOccupation !== null ? this.applicationwhoareyou.CurrentOccupation.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.applicationwhoareyou && this.applicationwhoareyou.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.applicationwhoareyou && this.applicationwhoareyou.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EducationCompletedTypeId: new DynamicLabel(
                'Education Completed Type',
                getMetaItemValue(this.educationCompletedTypes, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('EducationCompletedTypeId') && this.applicationwhoareyou.EducationCompletedTypeId !== null ? this.applicationwhoareyou.EducationCompletedTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ExerciseRoutine: new DynamicLabel(
                'Exercise Routine',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ExerciseRoutine') && this.applicationwhoareyou.ExerciseRoutine !== null ? this.applicationwhoareyou.ExerciseRoutine.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            EyeColorId: new DynamicLabel(
                'Eye Color',
                getMetaItemValue(this.eyeColors, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('EyeColorId') && this.applicationwhoareyou.EyeColorId !== null ? this.applicationwhoareyou.EyeColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            FamilyBirthDefects: new DynamicLabel(
                'Family Birth Defects',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('FamilyBirthDefects') && this.applicationwhoareyou.FamilyBirthDefects !== null ? this.applicationwhoareyou.FamilyBirthDefects.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Favorites: new DynamicLabel(
                'Favorites',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Favorites') && this.applicationwhoareyou.Favorites !== null ? this.applicationwhoareyou.Favorites.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Goals: new DynamicLabel(
                'Goals',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Goals') && this.applicationwhoareyou.Goals !== null ? this.applicationwhoareyou.Goals.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            HairTextureId: new DynamicLabel(
                'Hair Texture',
                getMetaItemValue(this.hairTextures, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HairTextureId') && this.applicationwhoareyou.HairTextureId !== null ? this.applicationwhoareyou.HairTextureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasAnxiety: new DynamicLabel(
                'Has Anxiety',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasAnxiety') && this.applicationwhoareyou.HasAnxiety !== null ? this.applicationwhoareyou.HasAnxiety : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasBeenInRehab: new DynamicLabel(
                'Has Been In Rehab',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenInRehab') && this.applicationwhoareyou.HasBeenInRehab !== null ? this.applicationwhoareyou.HasBeenInRehab : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasBeenPregnant: new DynamicLabel(
                'Has Been Pregnant',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenPregnant') && this.applicationwhoareyou.HasBeenPregnant !== null ? this.applicationwhoareyou.HasBeenPregnant : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasBeenUnderPsychiatristCare: new DynamicLabel(
                'Has Been Under Psychiatrist Care',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenUnderPsychiatristCare') && this.applicationwhoareyou.HasBeenUnderPsychiatristCare !== null ? this.applicationwhoareyou.HasBeenUnderPsychiatristCare : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasBeenVictimOfSeriousTraumaticEvent: new DynamicLabel(
                'Has Been Victim Of Serious Traumatic Event',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasBeenVictimOfSeriousTraumaticEvent') && this.applicationwhoareyou.HasBeenVictimOfSeriousTraumaticEvent !== null ? this.applicationwhoareyou.HasBeenVictimOfSeriousTraumaticEvent : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasDepression: new DynamicLabel(
                'Has Depression',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasDepression') && this.applicationwhoareyou.HasDepression !== null ? this.applicationwhoareyou.HasDepression : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasFamilyBirthDefects: new DynamicLabel(
                'Has Family Birth Defects',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasFamilyBirthDefects') && this.applicationwhoareyou.HasFamilyBirthDefects !== null ? this.applicationwhoareyou.HasFamilyBirthDefects : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasGivenBirth: new DynamicLabel(
                'Has Given Birth',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasGivenBirth') && this.applicationwhoareyou.HasGivenBirth !== null ? this.applicationwhoareyou.HasGivenBirth : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasMoodSwings: new DynamicLabel(
                'Has Mood Swings',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasMoodSwings') && this.applicationwhoareyou.HasMoodSwings !== null ? this.applicationwhoareyou.HasMoodSwings : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasPlasticSurgery: new DynamicLabel(
                'Has Plastic Surgery',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasPlasticSurgery') && this.applicationwhoareyou.HasPlasticSurgery !== null ? this.applicationwhoareyou.HasPlasticSurgery : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasPreviouslyDonatedEggs: new DynamicLabel(
                'Has Previously Donated Eggs',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasPreviouslyDonatedEggs') && this.applicationwhoareyou.HasPreviouslyDonatedEggs !== null ? this.applicationwhoareyou.HasPreviouslyDonatedEggs : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasSchizophreniaSigns: new DynamicLabel(
                'Has Schizophrenia Signs',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasSchizophreniaSigns') && this.applicationwhoareyou.HasSchizophreniaSigns !== null ? this.applicationwhoareyou.HasSchizophreniaSigns : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HighSchoolGpaId: new DynamicLabel(
                'High School Gpa',
                getMetaItemValue(this.highSchoolGpas, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HighSchoolGpaId') && this.applicationwhoareyou.HighSchoolGpaId !== null ? this.applicationwhoareyou.HighSchoolGpaId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HowManyLiveBirths: new DynamicLabel(
                'How Many Live Births',
                this.applicationwhoareyou && this.applicationwhoareyou.HowManyLiveBirths || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            IsCurrentlyListedOnAnotherEggDonorRoster: new DynamicLabel(
                'Is Currently Listed On Another Egg Donor Roster',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('IsCurrentlyListedOnAnotherEggDonorRoster') && this.applicationwhoareyou.IsCurrentlyListedOnAnotherEggDonorRoster !== null ? this.applicationwhoareyou.IsCurrentlyListedOnAnotherEggDonorRoster : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            NaturalHairColorId: new DynamicLabel(
                'Natural Hair Color',
                getMetaItemValue(this.naturalHairColors, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('NaturalHairColorId') && this.applicationwhoareyou.NaturalHairColorId !== null ? this.applicationwhoareyou.NaturalHairColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberOfChildren: new DynamicLabel(
                'Number Of Children',
                this.applicationwhoareyou && this.applicationwhoareyou.NumberOfChildren || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            NumberOfEggDonations: new DynamicLabel(
                'Number Of Egg Donations',
                this.applicationwhoareyou && this.applicationwhoareyou.NumberOfEggDonations || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            OtherAncestryFather: new DynamicLabel(
                'Other Ancestry Father',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryFather') && this.applicationwhoareyou.OtherAncestryFather !== null ? this.applicationwhoareyou.OtherAncestryFather.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherAncestryMother: new DynamicLabel(
                'Other Ancestry Mother',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryMother') && this.applicationwhoareyou.OtherAncestryMother !== null ? this.applicationwhoareyou.OtherAncestryMother.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherBirthControlMethod: new DynamicLabel(
                'Other Birth Control Method',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherBirthControlMethod') && this.applicationwhoareyou.OtherBirthControlMethod !== null ? this.applicationwhoareyou.OtherBirthControlMethod.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherCommentsForTweb: new DynamicLabel(
                'Other Comments For Tweb',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherCommentsForTweb') && this.applicationwhoareyou.OtherCommentsForTweb !== null ? this.applicationwhoareyou.OtherCommentsForTweb.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            ),
            OtherEggDonorRosters: new DynamicLabel(
                'Other Egg Donor Rosters',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherEggDonorRosters') && this.applicationwhoareyou.OtherEggDonorRosters !== null ? this.applicationwhoareyou.OtherEggDonorRosters.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PlasticSurgeryTypeAndYear: new DynamicLabel(
                'Plastic Surgery Type And Year',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('PlasticSurgeryTypeAndYear') && this.applicationwhoareyou.PlasticSurgeryTypeAndYear !== null ? this.applicationwhoareyou.PlasticSurgeryTypeAndYear.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            RaceId: new DynamicLabel(
                'Race',
                getMetaItemValue(this.races, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('RaceId') && this.applicationwhoareyou.RaceId !== null ? this.applicationwhoareyou.RaceId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SeriousTraumaticEventId: new DynamicLabel(
                'Serious Traumatic Event',
                getMetaItemValue(this.seriousTraumaticEvents, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('SeriousTraumaticEventId') && this.applicationwhoareyou.SeriousTraumaticEventId !== null ? this.applicationwhoareyou.SeriousTraumaticEventId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SkinTypeId: new DynamicLabel(
                'Skin Type',
                getMetaItemValue(this.skinTypes, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('SkinTypeId') && this.applicationwhoareyou.SkinTypeId !== null ? this.applicationwhoareyou.SkinTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TalentsAbilities: new DynamicLabel(
                'Talents Abilities',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('TalentsAbilities') && this.applicationwhoareyou.TalentsAbilities !== null ? this.applicationwhoareyou.TalentsAbilities.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            WhyInterestedInBecomingEggDonor: new DynamicLabel(
                'Why Interested In Becoming Egg Donor',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('WhyInterestedInBecomingEggDonor') && this.applicationwhoareyou.WhyInterestedInBecomingEggDonor !== null ? this.applicationwhoareyou.WhyInterestedInBecomingEggDonor.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
