<div *ngIf="formCreated">
    <div class="form-section-border">
        <h3>Who Are You</h3>
    </div>
    <form [formGroup]="whoAreYouForm" (ngSubmit)="formSubmitted()">
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.BodyStructureId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.EyeColorId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.NaturalHairColorId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HairTextureId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.SkinTypeId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.RaceId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.EducationCompletedTypeId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HighSchoolGpaId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CollegeMajor" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CollegeGpaId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CurrentOccupation" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.TalentsAbilities" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.Favorites" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CharacterDescription" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.Goals" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.ExerciseRoutine" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.WhyInterestedInBecomingEggDonor" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.HasPlasticSurgery"
            [form]="whoAreYouForm"
            (valueChanges)="onPlasticSurgeryValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showSurgeryTypeAndYear"
            [field]="abstractApplicationWhoAreYouControls.PlasticSurgeryTypeAndYear"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.HasBeenVictimOfSeriousTraumaticEvent"
            [form]="whoAreYouForm"
            (valueChanges)="onHasBeenVictimOfSeriousTraumaticEventChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showSeriousTraumaticEvent"
            [field]="abstractApplicationWhoAreYouControls.SeriousTraumaticEventId"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasDepression" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasMoodSwings" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasAnxiety" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasSchizophreniaSigns" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasBeenUnderPsychiatristCare" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasBeenInRehab" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.HasFamilyBirthDefects"
            [form]="whoAreYouForm"
            (valueChanges)="onHasFamilyBirthDefectsChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showFamilyBirthDefects"
            [field]="abstractApplicationWhoAreYouControls.FamilyBirthDefects"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.BirthControlTypeId"
            [form]="whoAreYouForm"
            (valueChanges)="onBirthControlTypeValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showOtherBirthControlMethod"
            [field]="abstractApplicationWhoAreYouControls.OtherBirthControlMethod"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field *ngIf="showBirthControlTimespan" [field]="abstractApplicationWhoAreYouControls.BirthControlTimespan" [form]="whoAreYouForm">
        </dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.HasBeenPregnant"
            [form]="whoAreYouForm"
            (valueChanges)="onHasBeenPreganantValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.HasGivenBirth"
            [form]="whoAreYouForm"
            (valueChanges)="onHasGivenBirthValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showHowManyLiveBirths"
            [field]="abstractApplicationWhoAreYouControls.HowManyLiveBirths"
            [form]="whoAreYouForm"
            (valueChanges)="onHowManyLiveBirthsValueChange($event)"
        ></dynamic-field>

        <ng-container formArrayName="ApplicationWhoAreYouChildrenArray">
            <ng-container *ngFor="let item of ApplicationWhoAreYouChildrenFormGroup; let i = index" [formGroupName]="i">
                <div style="padding: 10px; border-width: thin; border-style: solid">
                    <h3>Child Details - {{ i + 1 }}</h3>
                    <dynamic-field [field]="item.value.YearOfChildBirth" (valueChanges)="onYearOfChildBirthValueChange($event, i)"></dynamic-field>
                    <dynamic-field [field]="item.value.ChildGender" (valueChanges)="onChildGenderValueChange($event, i)"></dynamic-field>
                    <ng-container *ngIf="showIsBreastFeed[i]">
                        <dynamic-field [field]="item.value.IsBreastFeed" (valueChanges)="onIsBreastFeedValueChange($event, i)"></dynamic-field>
                    </ng-container>
                </div>
                <br />
            </ng-container>
        </ng-container>

        <dynamic-field
            *ngIf="showNumberOfChildren"
            [field]="abstractApplicationWhoAreYouControls.NumberOfChildren"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.HasPreviouslyDonatedEggs"
            [form]="whoAreYouForm"
            (valueChanges)="onPreviouslyDonatedValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showNumberOfEggsDonated"
            [field]="abstractApplicationWhoAreYouControls.NumberOfEggDonations"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.IsCurrentlyListedOnAnotherEggDonorRoster"
            [form]="whoAreYouForm"
            (valueChanges)="onListOnAnotherRosterValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showOtherEggRosters"
            [field]="abstractApplicationWhoAreYouControls.OtherEggDonorRosters"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.ApplicationFatherAncestries"
            [form]="whoAreYouForm"
            (valueChanges)="onFatherAncestryValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showOtherFatherAncestries"
            [field]="abstractApplicationWhoAreYouControls.OtherAncestryFather"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationWhoAreYouControls.ApplicationMotherAncestries"
            [form]="whoAreYouForm"
            (valueChanges)="onMotherAncestryValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="showOtherMotherAncestries"
            [field]="abstractApplicationWhoAreYouControls.OtherAncestryMother"
            [form]="whoAreYouForm"
        ></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherCommentsForTweb" [form]="whoAreYouForm"></dynamic-field>
        <div class="row col-md-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
    </form>
</div>
