import { EmailService } from './services/email.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { KeyboardShortcutService } from 'ngx-keyboard-shortcuts';

import { MtAlertsModule } from '@mt-ng2/alerts-module';
import { MtPreventDoubleClickButtonModule } from '@mt-ng2/disable-double-click';
import { MtDateModule } from '@mt-ng2/date-module';
import { BackButtonModule } from '@mt-ng2/back-button-module';

import { CommonService } from './services/common.service';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';

import { DashboardModule } from '@mt-ng2/dashboard';
import { recommendedPagesModuleConfig } from './configs/recommended-pages.config';
import { PageTitlesModule } from '@mt-ng2/page-titles';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module-config';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form-config';
import { ContactStatusService } from '@model/shared-entities/contacts/contact-status.service';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { FeatureModule } from './feature.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ReferralTypeService } from './services/referral-type.service';
import { MedicalIssueTypesService } from './services/medical-issue-types.service';
import { MedicalIssueRelationshipTypesService } from './services/medical-issue-relationship-types.service';
import { MedicalIssueOptionsService } from './services/medical-issue-options.service';
import { UnitsOfMeasurementService } from './services/units-of-measurement.service';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { DonorStatusesService } from './services/donor-statuses.service';
import { CustomSsnComponent } from './components/custom-ssn.component';
import { MiniDashboardListComponent } from './mini-dashboard-list/mini-dashboard-list.component';
import { OverrriddenDashboardComponent } from '../dashboard/overridden-dashboard.component';
import { AppointmentsMiniListComponent } from '../appointments/appointments-mini-list/appointments-mini-list.component';
import { AuditedDateControlComponent } from './components/audited-date-control/audited-date-control.component';
import { BmiControlComponent } from './components/bmi-control/bmi-control.component';
import { OocytesControlComponent } from './components/oocytes-control/oocytes-control.component';
import { InventoryVesselComponent } from './components/inventory-vessels/inventory-vessel.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { InventoryVesselFormComponent } from './components/inventory-vessels/inventory-vessel-form/inventory-vessel-form.component';
import { TriggerDataControlComponent } from './components/trigger-data-control/trigger-data-control.component';
import { SendEmailTemplateComponent } from './components/send-email/send-email-template.component';
import { EmailModalModule } from '@mt-ng2/email-modal-module';
import { NotesComponent } from './components/notes/notes.component';
import { FormYesNoButtonComponent } from './components/form-yes-no-button.component';
import { DyanmicLabelComponent } from './components/dynamic-label.component';
import { DonorSearchComponent } from './components/donor-search/donor-search.component';
import { RecipientSearchComponent } from './components/recipient-search/recipient-search.component';
import { VisibilityModule } from '@mt-ng2/visibility-module';
import { DonorSearchResultComponent } from './components/donor-search/donor-search-result.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents/upload-documents.component';
import { UploadDocumentsDetailComponent } from './components/upload-documents/upload-documents-detail/upload-documents-detail.component';
import { InventoryLocationFormComponent } from './components/inventory-location/inventory-location-form.component';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { UploadGeneticTestsComponent } from '../donors/donor-cycles/donor-cycle-step/donor-cycle-step-specific/upload-genetic-tests/upload-genetic-tests.component';
import { CustomCancelControlComponent } from './components/custom-cancel.component';
import { FileExtensionChangePipe } from './pipes/file-extension-change.pipe';
import { FallbackImgDirective } from './directives/image-fallback.directive';
import { ReportUsersService } from './services/report-users.service';
import { AdvancedReportingModuleConfigToken, AdvancedReportingModuleUserServiceToken } from '@mt-ng2/advanced-reporting-module-config';
import { AdminPortalAdvancedReportingModuleWithRouting } from './advanced-reporting.module';
import { reportingModuleConfig } from './configs/reporting.config';
import { ResultsPerPageComponent } from './components/results-per-page.component';
import { MultipleInventoryLocationsFormComponent } from './components/inventory-location/multiple-inventory-locations-form/multiple-inventory-locations-form.component';
import { WysiwygModule } from '@mt-ng2/wysiwyg-control';
import { DonorReviewsComponent } from './components/donor-reviews/donor-reviews.component';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 25,
};

@NgModule({
    declarations: [
        CustomSsnComponent,
        OverrriddenDashboardComponent,
        AppointmentsMiniListComponent,
        MiniDashboardListComponent,
        AuditedDateControlComponent,
        BmiControlComponent,
        OocytesControlComponent,
        InventoryVesselComponent,
        InventoryVesselFormComponent,
        SendEmailTemplateComponent,
        TriggerDataControlComponent,
        NotesComponent,
        FormYesNoButtonComponent,
        DyanmicLabelComponent,
        DonorSearchComponent,
        RecipientSearchComponent,
        DonorSearchResultComponent,
        UploadDocumentsComponent,
        UploadGeneticTestsComponent,
        UploadDocumentsDetailComponent,
        InventoryLocationFormComponent,
        MultipleInventoryLocationsFormComponent,
        CustomCancelControlComponent,
        FileExtensionChangePipe,
        FallbackImgDirective,
        ResultsPerPageComponent,
        DonorReviewsComponent,
    ],
    entryComponents: [
        AuditedDateControlComponent,
        BmiControlComponent,
        OocytesControlComponent,
        TriggerDataControlComponent,
        FormYesNoButtonComponent,
    ],
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MtAlertsModule,
        MtPreventDoubleClickButtonModule,
        BackButtonModule,
        OverrriddenDashboardComponent,
        KeyboardShortcutModule,
        MiniDashboardListComponent,
        PageTitlesModule,
        FeatureModule,
        NgxSkeletonLoaderModule,
        MtDocumentControlModule,
        CustomSsnComponent,
        AuditedDateControlComponent,
        BmiControlComponent,
        OocytesControlComponent,
        InventoryVesselComponent,
        SendEmailTemplateComponent,
        TriggerDataControlComponent,
        EmailModalModule,
        NotesComponent,
        FormYesNoButtonComponent,
        DyanmicLabelComponent,
        DonorSearchComponent,
        RecipientSearchComponent,
        DonorSearchResultComponent,
        UploadDocumentsComponent,
        UploadGeneticTestsComponent,
        UploadDocumentsDetailComponent,
        InventoryLocationFormComponent,
        MultipleInventoryLocationsFormComponent,
        MtSearchFilterDaterangeModule,
        MultiselectControlModule,
        CustomCancelControlComponent,
        FileExtensionChangePipe,
        FallbackImgDirective,
        ResultsPerPageComponent,
        WysiwygModule,
        DonorReviewsComponent,
    ],
    imports: [
        CommonModule,
        EmailModalModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MtAlertsModule,
        MtPreventDoubleClickButtonModule,
        BackButtonModule,
        KeyboardShortcutModule,
        DashboardModule.forRoot(recommendedPagesModuleConfig),
        PageTitlesModule,
        FeatureModule,
        NgxSkeletonLoaderModule,
        MtDocumentControlModule,
        ModalModule,
        MtDateModule,
        VisibilityModule,
        MtSearchFilterDaterangeModule,
        MultiselectControlModule,
        AdminPortalAdvancedReportingModuleWithRouting,
        WysiwygModule,
    ],
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                ReferralTypeService,
                MedicalIssueTypesService,
                MedicalIssueRelationshipTypesService,
                MedicalIssueOptionsService,
                UnitsOfMeasurementService,
                DonorStatusesService,
                CommonService,
                EmailService,
                ContactStatusService,
                KeyboardShortcutService,
                PrintModeService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService, datePickerShowClearButton: true } },
                { provide: AdvancedReportingModuleConfigToken, useValue: reportingModuleConfig },
                { provide: AdvancedReportingModuleUserServiceToken, useExisting: ReportUsersService },
            ],
        };
    }
}
