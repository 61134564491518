import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IApplicationIssue } from '@model/interfaces/application-issue';
import { ApplicationIssueDynamicControls, IApplicationIssueDynamicControlsParameters } from '@model/form-controls/application-issue.form-controls';

export class MedicalIssuesDynamicConfig<T extends IApplicationIssue> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private medicalIssues: T, private configControls?: string[]) {
        super();
        const additionalParams: IApplicationIssueDynamicControlsParameters = {};
        const dynamicControls = new ApplicationIssueDynamicControls(this.medicalIssues, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['CurrentMedications', 'HasSurgeries', 'IsTakingMedication', 'OtherIssues', 'Surgeries'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
