import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorFamilyMemberHistory } from '../interfaces/donor-family-member-history';
import { IEducationCompletedType } from '../interfaces/education-completed-type';
import { IEyeColor } from '../interfaces/eye-color';
import { INaturalHairColor } from '../interfaces/natural-hair-color';
import { IHairTexture } from '../interfaces/hair-texture';
import { IUnitsOfMeasurement } from '../interfaces/units-of-measurement';
import { IRace } from '../interfaces/race';
import { ISkinType } from '../interfaces/skin-type';

export interface IDonorFamilyMemberHistoryDynamicControlsParameters {
    formGroup?: string;
    heights?: IUnitsOfMeasurement[];
    heightUnits?: IUnitsOfMeasurement[];
    weights?: IUnitsOfMeasurement[];
    weightUnits?: IUnitsOfMeasurement[];
    eyeColors?: IEyeColor[];
    hairColors?: INaturalHairColor[];
    hairTextures?: IHairTexture[];
    skinTypes?: ISkinType[];
    educationCompletedTypes?: IEducationCompletedType[];
    races?: IRace[];
}

export class DonorFamilyMemberHistoryDynamicControls {

    formGroup: string;
    heights: IUnitsOfMeasurement[];
    heightUnits: IUnitsOfMeasurement[];
    weights: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];
    eyeColors: IEyeColor[];
    hairColors: INaturalHairColor[];
    hairTextures: IHairTexture[];
    skinTypes: ISkinType[];
    educationCompletedTypes: IEducationCompletedType[];
    races: IRace[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorfamilymemberhistory?: IDonorFamilyMemberHistory, additionalParameters?: IDonorFamilyMemberHistoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorFamilyMemberHistory';
        this.heights = additionalParameters && additionalParameters.heights || undefined;
        this.heightUnits = additionalParameters && additionalParameters.heightUnits || undefined;
        this.weights = additionalParameters && additionalParameters.weights || undefined;
        this.weightUnits = additionalParameters && additionalParameters.weightUnits || undefined;
        this.eyeColors = additionalParameters && additionalParameters.eyeColors || undefined;
        this.hairColors = additionalParameters && additionalParameters.hairColors || undefined;
        this.hairTextures = additionalParameters && additionalParameters.hairTextures || undefined;
        this.skinTypes = additionalParameters && additionalParameters.skinTypes || undefined;
        this.educationCompletedTypes = additionalParameters && additionalParameters.educationCompletedTypes || undefined;
        this.races = additionalParameters && additionalParameters.races || undefined;

        this.Form = {
            Age: new DynamicField({
                formGroup: this.formGroup,
                label: 'Age',
                name: 'Age',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.Age || null,
            }),
            AgeAtDeath: new DynamicField({
                formGroup: this.formGroup,
                label: 'Age At Death',
                name: 'AgeAtDeath',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.AgeAtDeath || null,
            }),
            CauseOfDeath: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cause Of Death',
                name: 'CauseOfDeath',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('CauseOfDeath') && this.donorfamilymemberhistory.CauseOfDeath !== null ? this.donorfamilymemberhistory.CauseOfDeath.toString() : '',
            }),
            Deceased: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deceased',
                name: 'Deceased',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Deceased') && this.donorfamilymemberhistory.Deceased !== null ? this.donorfamilymemberhistory.Deceased : false,
            }),
            EducationCompletedTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Education Completed Type',
                name: 'EducationCompletedTypeId',
                options: this.educationCompletedTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.EducationCompletedTypeId || null,
            }),
            EyeColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eye Color',
                name: 'EyeColorId',
                options: this.eyeColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.EyeColorId || null,
            }),
            HairColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hair Color',
                name: 'HairColorId',
                options: this.hairColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.HairColorId || null,
            }),
            HairTextureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hair Texture',
                name: 'HairTextureId',
                options: this.hairTextures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.HairTextureId || null,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: this.heights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.Height || null,
            }),
            HeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height Unit',
                name: 'HeightUnitId',
                options: this.heightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.HeightUnitId || null,
            }),
            IsFullSibling: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Full Sibling',
                name: 'IsFullSibling',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsFullSibling') && this.donorfamilymemberhistory.IsFullSibling !== null ? this.donorfamilymemberhistory.IsFullSibling : false,
            }),
            IsHalfSiblingFather: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Half Sibling Father',
                name: 'IsHalfSiblingFather',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsHalfSiblingFather') && this.donorfamilymemberhistory.IsHalfSiblingFather !== null ? this.donorfamilymemberhistory.IsHalfSiblingFather : false,
            }),
            IsHalfSiblingMother: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Half Sibling Mother',
                name: 'IsHalfSiblingMother',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsHalfSiblingMother') && this.donorfamilymemberhistory.IsHalfSiblingMother !== null ? this.donorfamilymemberhistory.IsHalfSiblingMother : false,
            }),
            IsSibling: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Sibling',
                name: 'IsSibling',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsSibling') && this.donorfamilymemberhistory.IsSibling !== null ? this.donorfamilymemberhistory.IsSibling : false,
            }),
            Occupation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Occupation',
                name: 'Occupation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(500) ],
                validators: { 'required': true, 'maxlength': 500 },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Occupation') && this.donorfamilymemberhistory.Occupation !== null ? this.donorfamilymemberhistory.Occupation.toString() : '',
            }),
            PlaceOfBirth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Place Of Birth',
                name: 'PlaceOfBirth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(2) ],
                validators: { 'maxlength': 2 },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('PlaceOfBirth') && this.donorfamilymemberhistory.PlaceOfBirth !== null ? this.donorfamilymemberhistory.PlaceOfBirth.toString() : '',
            }),
            RaceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Race',
                name: 'RaceId',
                options: this.races,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.RaceId || null,
            }),
            Skills: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skills',
                name: 'Skills',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(500) ],
                validators: { 'required': true, 'maxlength': 500 },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Skills') && this.donorfamilymemberhistory.Skills !== null ? this.donorfamilymemberhistory.Skills.toString() : '',
            }),
            SkinTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skin Type',
                name: 'SkinTypeId',
                options: this.skinTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.SkinTypeId || null,
            }),
            Weight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight',
                name: 'Weight',
                options: this.weights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.Weight || null,
            }),
            WeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight Unit',
                name: 'WeightUnitId',
                options: this.weightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilymemberhistory && this.donorfamilymemberhistory.WeightUnitId || null,
            }),
        };

        this.View = {
            Age: new DynamicLabel(
                'Age',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.Age || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            AgeAtDeath: new DynamicLabel(
                'Age At Death',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.AgeAtDeath || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            CauseOfDeath: new DynamicLabel(
                'Cause Of Death',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('CauseOfDeath') && this.donorfamilymemberhistory.CauseOfDeath !== null ? this.donorfamilymemberhistory.CauseOfDeath.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Deceased: new DynamicLabel(
                'Deceased',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Deceased') && this.donorfamilymemberhistory.Deceased !== null ? this.donorfamilymemberhistory.Deceased : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            EducationCompletedTypeId: new DynamicLabel(
                'Education Completed Type',
                getMetaItemValue(this.educationCompletedTypes, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('EducationCompletedTypeId') && this.donorfamilymemberhistory.EducationCompletedTypeId !== null ? this.donorfamilymemberhistory.EducationCompletedTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            EyeColorId: new DynamicLabel(
                'Eye Color',
                getMetaItemValue(this.eyeColors, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('EyeColorId') && this.donorfamilymemberhistory.EyeColorId !== null ? this.donorfamilymemberhistory.EyeColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HairColorId: new DynamicLabel(
                'Hair Color',
                getMetaItemValue(this.hairColors, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('HairColorId') && this.donorfamilymemberhistory.HairColorId !== null ? this.donorfamilymemberhistory.HairColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HairTextureId: new DynamicLabel(
                'Hair Texture',
                getMetaItemValue(this.hairTextures, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('HairTextureId') && this.donorfamilymemberhistory.HairTextureId !== null ? this.donorfamilymemberhistory.HairTextureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                getMetaItemValue(this.heights, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Height') && this.donorfamilymemberhistory.Height !== null ? this.donorfamilymemberhistory.Height : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HeightUnitId: new DynamicLabel(
                'Height Unit',
                getMetaItemValue(this.heightUnits, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('HeightUnitId') && this.donorfamilymemberhistory.HeightUnitId !== null ? this.donorfamilymemberhistory.HeightUnitId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsFullSibling: new DynamicLabel(
                'Is Full Sibling',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsFullSibling') && this.donorfamilymemberhistory.IsFullSibling !== null ? this.donorfamilymemberhistory.IsFullSibling : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsHalfSiblingFather: new DynamicLabel(
                'Is Half Sibling Father',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsHalfSiblingFather') && this.donorfamilymemberhistory.IsHalfSiblingFather !== null ? this.donorfamilymemberhistory.IsHalfSiblingFather : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsHalfSiblingMother: new DynamicLabel(
                'Is Half Sibling Mother',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsHalfSiblingMother') && this.donorfamilymemberhistory.IsHalfSiblingMother !== null ? this.donorfamilymemberhistory.IsHalfSiblingMother : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsSibling: new DynamicLabel(
                'Is Sibling',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('IsSibling') && this.donorfamilymemberhistory.IsSibling !== null ? this.donorfamilymemberhistory.IsSibling : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Occupation: new DynamicLabel(
                'Occupation',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Occupation') && this.donorfamilymemberhistory.Occupation !== null ? this.donorfamilymemberhistory.Occupation.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PlaceOfBirth: new DynamicLabel(
                'Place Of Birth',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('PlaceOfBirth') && this.donorfamilymemberhistory.PlaceOfBirth !== null ? this.donorfamilymemberhistory.PlaceOfBirth.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            RaceId: new DynamicLabel(
                'Race',
                getMetaItemValue(this.races, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('RaceId') && this.donorfamilymemberhistory.RaceId !== null ? this.donorfamilymemberhistory.RaceId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Skills: new DynamicLabel(
                'Skills',
                this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Skills') && this.donorfamilymemberhistory.Skills !== null ? this.donorfamilymemberhistory.Skills.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SkinTypeId: new DynamicLabel(
                'Skin Type',
                getMetaItemValue(this.skinTypes, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('SkinTypeId') && this.donorfamilymemberhistory.SkinTypeId !== null ? this.donorfamilymemberhistory.SkinTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Weight: new DynamicLabel(
                'Weight',
                getMetaItemValue(this.weights, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('Weight') && this.donorfamilymemberhistory.Weight !== null ? this.donorfamilymemberhistory.Weight : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WeightUnitId: new DynamicLabel(
                'Weight Unit',
                getMetaItemValue(this.weightUnits, this.donorfamilymemberhistory && this.donorfamilymemberhistory.hasOwnProperty('WeightUnitId') && this.donorfamilymemberhistory.WeightUnitId !== null ? this.donorfamilymemberhistory.WeightUnitId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
