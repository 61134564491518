import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IApplicationIssue } from '../interfaces/application-issue';

export interface IApplicationIssueDynamicControlsParameters {
    formGroup?: string;
}

export class ApplicationIssueDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private applicationissue?: IApplicationIssue, additionalParameters?: IApplicationIssueDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ApplicationIssue';

        this.Form = {
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationissue && this.applicationissue.hasOwnProperty('Complete') && this.applicationissue.Complete !== null ? this.applicationissue.Complete : false,
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: false,
            }),
            CurrentMedications: new DynamicField({
                formGroup: this.formGroup,
                label: 'Current Medications',
                name: 'CurrentMedications',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.applicationissue && this.applicationissue.hasOwnProperty('CurrentMedications') && this.applicationissue.CurrentMedications !== null ? this.applicationissue.CurrentMedications.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.applicationissue && this.applicationissue.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationissue && this.applicationissue.DateModified || null,
            }),
            HasSurgeries: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Surgeries',
                name: 'HasSurgeries',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationissue && this.applicationissue.hasOwnProperty('HasSurgeries') && this.applicationissue.HasSurgeries !== null ? this.applicationissue.HasSurgeries : false,
            }),
            IsTakingMedication: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Taking Medication',
                name: 'IsTakingMedication',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationissue && this.applicationissue.hasOwnProperty('IsTakingMedication') && this.applicationissue.IsTakingMedication !== null ? this.applicationissue.IsTakingMedication : false,
            }),
            OtherIssues: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Issues',
                name: 'OtherIssues',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.applicationissue && this.applicationissue.hasOwnProperty('OtherIssues') && this.applicationissue.OtherIssues !== null ? this.applicationissue.OtherIssues.toString() : '',
            }),
            Surgeries: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surgeries',
                name: 'Surgeries',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.applicationissue && this.applicationissue.hasOwnProperty('Surgeries') && this.applicationissue.Surgeries !== null ? this.applicationissue.Surgeries.toString() : '',
            }),
        };

        this.View = {
            Complete: new DynamicLabel(
                'Complete',
                this.applicationissue && this.applicationissue.hasOwnProperty('Complete') && this.applicationissue.Complete !== null ? this.applicationissue.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Submitted: new DynamicLabel(
                'Submitted',
                this.applicationissue && this.applicationissue.hasOwnProperty('Submitted') && this.applicationissue.Submitted !== null ? this.applicationissue.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CurrentMedications: new DynamicLabel(
                'Current Medications',
                this.applicationissue && this.applicationissue.hasOwnProperty('CurrentMedications') && this.applicationissue.CurrentMedications !== null ? this.applicationissue.CurrentMedications.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.applicationissue && this.applicationissue.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.applicationissue && this.applicationissue.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HasSurgeries: new DynamicLabel(
                'Has Surgeries',
                this.applicationissue && this.applicationissue.hasOwnProperty('HasSurgeries') && this.applicationissue.HasSurgeries !== null ? this.applicationissue.HasSurgeries : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsTakingMedication: new DynamicLabel(
                'Is Taking Medication',
                this.applicationissue && this.applicationissue.hasOwnProperty('IsTakingMedication') && this.applicationissue.IsTakingMedication !== null ? this.applicationissue.IsTakingMedication : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherIssues: new DynamicLabel(
                'Other Issues',
                this.applicationissue && this.applicationissue.hasOwnProperty('OtherIssues') && this.applicationissue.OtherIssues !== null ? this.applicationissue.OtherIssues.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Surgeries: new DynamicLabel(
                'Surgeries',
                this.applicationissue && this.applicationissue.hasOwnProperty('Surgeries') && this.applicationissue.Surgeries !== null ? this.applicationissue.Surgeries.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
