import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { ApplicationService } from '../services/application.service';
import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { ApplicationsEntityListConfig } from './applications.entity-list-config';
import { IVwApplicationInformation } from '@model/interfaces/vw-application-information';
import { StickySearchEntity, StickySearchStateService } from '@common/services/sticky-search.state.service';
import { ISearchbarControlAPI } from '@mt-ng2/searchbar-control';
import { ApplicationStatusService } from '@common/services/application-status.service';
import { ApplicationStatuses } from '@model/enums/application-statuses.enum';

@Component({
    selector: 'app-applications',
    templateUrl: './applications.component.html',
})
export class ApplicationsComponent implements OnInit {
    applications: IVwApplicationInformation[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    applicationStatuses: MtSearchFilterItem[] = [];
    includeDenied = false;
    includeArchived = false;
    entityListConfig = new ApplicationsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    searchbarApi: ISearchbarControlAPI;

    canAddApplication = false;

    constructor(
        private applicationService: ApplicationService,
        private claimsService: ClaimsService,
        private router: Router,
        private stickySearchService: StickySearchStateService,
        private applicationStatusService: ApplicationStatusService,
    ) {}

    ngOnInit(): void {
        this.canAddApplication = this.claimsService.hasClaim(ClaimTypes.DonorApplication, [ClaimValues.FullAccess]);
        this.applicationStatusService.getSearchFilterItems().subscribe((items) => {
            this.applicationStatuses = items.filter((i) => i.Item.Id !== ApplicationStatuses.Complete);
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedApplicationStatusIds: number[] = this.getSelectedFilters(this.applicationStatuses);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ApplicationProgressIds',
                valueArray: selectedApplicationStatusIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeDenied',
                value: String(this.includeDenied),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeArchived',
                value: String(this.includeArchived),
            }),
        );

        return _extraSearchParams;
    }

    getApplicationsCall(lastSearch?: IEntitySearchParams): Observable<HttpResponse<IVwApplicationInformation[]>> {
        let searchparams;
        if (lastSearch) {
            searchparams = new SearchParams(lastSearch);
        } else {
            const search = this.query;
            const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                order: this.order,
                orderDirection: this.orderDirection,
                query: search && search.length > 0 ? search : '',
                skip: (this.currentPage - 1) * this.itemsPerPage,
                take: this.itemsPerPage,
            };
            this.stickySearchService.setSearch(searchEntity, StickySearchEntity.Applications);
            searchparams = new SearchParams(searchEntity);
        }
        return this.applicationService.getViews(searchparams);
    }

    getApplications(lastSearch?: IEntitySearchParams): void {
        this.getApplicationsCall(lastSearch).subscribe((answer) => {
            this.applications = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getApplications();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getApplications();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getApplications();
    }

    applicationSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/applications', event.entity.Id]);
    }

    toggleDenied(event: any): void {
        this.includeDenied = !this.includeDenied;
        this.getApplications();
    }

    toggleArchived(event: any): void {
        this.includeArchived = !this.includeArchived;
        this.getApplications();
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getApplications();
    }

    setStickySearch(): void {
        const lastSearch = this.stickySearchService.getSearch(StickySearchEntity.Applications);
        if (lastSearch) {
            if (lastSearch.query) {
                this.searchbarApi.getSearchControl().patchValue(lastSearch.query);
            }
            if (lastSearch.extraParams.length) {
                let applicationProgressIds = lastSearch.extraParams.find((ep) => ep.name === 'ApplicationProgressIds');
                if (applicationProgressIds) {
                    this.applicationStatuses.forEach((rs) => {
                        if (applicationProgressIds.valueArray.includes(rs.Item.Id)) {
                            rs.Selected = true;
                        }
                    });
                }
                let includeDenied = lastSearch.extraParams.find((ep) => ep.name === 'IncludeDenied');
                if (includeDenied) {
                    this.includeDenied = includeDenied.value === 'true';
                }
            }
        }
        this.getApplications();
    }
}
