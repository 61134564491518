import { DynamicField, InputTypes, LabelPositions, DynamicLabel, DynamicFieldType, DynamicFieldTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import {
    ApplicationBasicInfoDynamicControls,
    IApplicationBasicInfoDynamicControlsParameters,
} from '@model/form-controls/application-basic-info.form-controls';
import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';
import { convertInchesToFeetAndInches } from '@common/libraries/parse-height.library';
import { Validators } from '@angular/forms';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';

export interface IApplicationBasicInfoDynamicControlsParametersPartial extends IApplicationBasicInfoDynamicControlsParameters {
    countries: ICountryMetaItem[];
    states: IStateMetaItem[];
}

export class ApplicationReviewBasicInfoDynamicControlsPartial extends ApplicationBasicInfoDynamicControls {
    constructor(appBasicInfoPartial?: IApplicationBasicInfo, additionalParameters?: IApplicationBasicInfoDynamicControlsParametersPartial) {
        super(appBasicInfoPartial, additionalParameters);

        (<DynamicLabel>this.View.Ssn).label = 'Social Security Number';
        (<DynamicLabel>this.View.Ssn).value = appBasicInfoPartial.Ssn
            ? appBasicInfoPartial.Ssn.slice(0, 3) + '-' + appBasicInfoPartial.Ssn.slice(3, 5) + '-' + appBasicInfoPartial.Ssn.slice(5)
            : null;

        (<DynamicLabel>this.View.HeightUnitId).value =
            appBasicInfoPartial.HeightUnitId === UnitsOfMeasurementEnums.FeetAndInches ? 'Feet & Inches' : 'Meters';
        (<DynamicLabel>this.View.Height).value =
            appBasicInfoPartial.HeightUnitId === UnitsOfMeasurementEnums.FeetAndInches
                ? convertInchesToFeetAndInches(appBasicInfoPartial.Height)
                : appBasicInfoPartial.Height;

        (<DynamicLabel>this.View.Weight).value = appBasicInfoPartial.Weight;
        (<DynamicLabel>this.View.WeightUnitId).value = appBasicInfoPartial.WeightUnitId
            ? additionalParameters.weights.find((unit) => unit.Id === appBasicInfoPartial.WeightUnitId).Name
            : '';

        (<DynamicLabel>this.View.ApplicantPhoneNumber).label = 'Phone';
        (<DynamicLabel>this.View.ApplicantPhoneNumber).type.inputType = InputTypes.Phone;

        (<DynamicField>this.Form.ApplicantPhoneNumber).labelHtml = '<label>Phone</label>';
        (<DynamicField>this.Form.ApplicantPhoneNumber).type.inputType = InputTypes.Phone;
        (<DynamicField>this.Form.ApplicantPhoneNumber).validation = [Validators.minLength(10)];
        (<DynamicField>this.Form.ApplicantPhoneNumber).validators = { minlength: 10 };

        (<DynamicField>this.Form.Dob).type.datepickerOptions.minDate = { year: 1900, day: 1, month: 1 };

        (<DynamicLabel>this.View.CountryCode).label = 'Country';
        (<DynamicLabel>this.View.CountryCode).value = appBasicInfoPartial.CountryCode
            ? additionalParameters.countries.find((c) => c.CountryCode === appBasicInfoPartial.CountryCode).Name
            : null;

        (<DynamicLabel>this.View.StateCode).label = 'State';
        (<DynamicLabel>this.View.StateCode).value = appBasicInfoPartial.StateCode
            ? additionalParameters.states.find((c) => c.StateCode === appBasicInfoPartial.StateCode).Name
            : null;

        (<DynamicLabel>this.View.PlaceOfBirth).value = appBasicInfoPartial?.PlaceOfBirth
            ? additionalParameters.countries.find((c) => c.CountryCode === appBasicInfoPartial?.PlaceOfBirth).Name
            : null;

        (<DynamicField>this.Form.PlaceOfBirth) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Place of birth',
            name: 'PlaceOfBirth',
            options: additionalParameters.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                appBasicInfoPartial?.PlaceOfBirth && additionalParameters.countries
                    ? additionalParameters.countries.find((c) => c.CountryCode === appBasicInfoPartial?.PlaceOfBirth).Id
                    : null,
        });

        (<DynamicField>this.Form.ReferralTypeId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Referral Type',
            name: 'ReferralTypeId',
            options: this.referralTypes.filter((x) => !x.OutOfUse || x.Id === appBasicInfoPartial.ReferralTypeId),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.ReferralTypeId || null,
        });

        (<DynamicField>this.Form.Comment) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Comment',
            name: 'Comment',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(100), Validators.required],
            validators: { maxlength: 100, required: true },
            value: appBasicInfoPartial.Comment !== null ? appBasicInfoPartial.Comment.toString() : '',
        });

        (<DynamicField>this.Form.Address1) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 1',
            name: 'Address1',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.Address1 || null,
        });

        (<DynamicField>this.Form.Address2) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 2',
            name: 'Address2',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: appBasicInfoPartial.Address2 || null,
        });

        (<DynamicField>this.Form.City) = new DynamicField({
            formGroup: this.formGroup,
            label: 'City',
            name: 'City',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.City || null,
        });

        (<DynamicField>this.Form.State) = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'State',
            options: additionalParameters.states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.StateCode
                ? additionalParameters.states.find((state) => state.StateCode === appBasicInfoPartial.StateCode).Id
                : null,
        });

        (<DynamicField>this.Form.Country) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Country',
            name: 'Country',
            options: additionalParameters.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.CountryCode
                ? additionalParameters.countries.find((country) => country.CountryCode === appBasicInfoPartial.CountryCode).Id
                : null,
        });

        (<DynamicField>this.Form.Province) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Province',
            name: 'Province',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.Province || null,
        });

        (<DynamicField>this.Form.Zip) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Zip',
            name: 'Zip',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
            validators: { required: true, minlength: 5, maxlength: 5 },
            value: appBasicInfoPartial.Zip || null,
        });

        (<DynamicField>this.Form.Feet) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Feet',
            name: 'Feet',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: appBasicInfoPartial.Height ? Math.floor(appBasicInfoPartial.Height / 12) : null,
        });

        (<DynamicField>this.Form.Inches) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Inches',
            name: 'Inches',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(12)],
            validators: { required: true, max: 12 },
            value: appBasicInfoPartial.Height ? appBasicInfoPartial.Height % 12 : null,
        });

        (<DynamicField>this.Form.Meters) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Meters',
            name: 'Meters',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Float,
                scale: 2,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: appBasicInfoPartial.Height ? appBasicInfoPartial.Height : null,
        });

        (<DynamicField>this.Form.SelectedHeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Height',
            name: 'SelectedHeightUnit',
            options: additionalParameters.heights,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.HeightUnitId ? appBasicInfoPartial.HeightUnitId : null,
        });

        (<DynamicField>this.Form.SelectedWeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            name: 'SelectedWeightUnit',
            options: additionalParameters.weights,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: appBasicInfoPartial.WeightUnitId ? appBasicInfoPartial.WeightUnitId : null,
        });

        (<DynamicField>this.Form.Weight) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            name: 'Weight',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(300)],
            validators: { required: true, max: 300 },
            value: appBasicInfoPartial.Weight ? appBasicInfoPartial.Weight : null,
        });
    }
}
