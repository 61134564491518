import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IDonor } from '@model/interfaces/donor';
import { Observable } from 'rxjs';
import { MilestoneStatuses } from '@model/enums/milestone-statuses.enum';
import { SearchParams } from '@mt-ng2/common-classes';
import { catchError, map } from 'rxjs/operators';
import { IVwDonorEggInventory } from '@model/interfaces/vw-donor-egg-inventory';
import { IVwDonorAvailableInventory } from '@model/interfaces/vw-donor-available-inventory';
import { IEmail } from '@mt-ng2/email-modal-module';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IAddress } from '@model/interfaces/base';
import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';

export const emptyDonor: IDonor = {
    Active: false,
    ApplicationId: 0,
    Approved: false,
    Archived: false,
    AvailableInCA: false,
    AvailableInAU: false,
    AvailableInUK: false,
    AvailableInOther: false,
    BabyBundle: false,
    DateCreated: null,
    DonorBasicInfoId: 0,
    DonorId: 0,
    DonorIssueId: 0,
    DonorPreliminaryHistoryId: 0,
    DonorStatusId: 0,
    DonorWhoAreYouId: 0,
    FamilyBundle: false,
    Id: 0,
    PremiumBankedEggs: false,
    BankedCustomChoice : false,
    Published: false,
    UserId: 0,
};

export interface IDonorMilestone {
    StatusId: MilestoneStatuses;
    MilestoneName: string;
}

@Injectable({
    providedIn: 'root',
})
export class DonorService extends BaseService<IDonor> {
    constructor(public http: HttpClient) {
        super('/donors', http);
    }

    getRecipientPortalUrl(): Observable<string> {
        return this.http.get<string>(`/api/v1/donors/recipient-portal-link`);
    }

    getEmptyDonor(): IDonor {
        return { ...emptyDonor };
    }

    getDonorMilestones(id): Observable<IDonorMilestone[]> {
        return this.http.get<IDonorMilestone[]>(`/api/v1/donors/${id}/milestones`);
    }

    getDonorHelloSignDocument(id: number, templateId: number): Observable<Blob> {
        return this.http.get(`/api/v1/donors/${id}/helloSign/${templateId}`, { responseType: 'blob' as 'blob' });
    }

    getDonorInventory(searchParams: SearchParams): Observable<HttpResponse<IVwDonorAvailableInventory[]>> {
        let params = this.getHttpParams(searchParams);
        return this.http
            .get<IVwDonorAvailableInventory[]>(`/donors/_search/inventory`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    getDonorSalesReservation(donorId: number): Observable<ISalesOrderReservation[]> {
        return this.http.get<ISalesOrderReservation[]>(`/donors/reservation/${donorId}`).pipe(catchError(this.handleError));
    }

    getPersonalInformationMetaData(): Observable<IPersonalInformationMetaData> {
        return this.http.get<IPersonalInformationMetaData>('/options/personal-information');
    }

    updateDonorAddress(donorId: number, addressId: number, updatedAddress: IAddress): Observable<null> {
        return this.http.post<null>(`/api/v1/donors/${donorId}/address/${addressId}`, updatedAddress);
    }

    getAvailableInventoryByDonor(donorId: number): Observable<IVwDonorAvailableInventory> {
        return this.http.get<IVwDonorAvailableInventory>(`/donors/${donorId}/inventory`);
    }

    getById(id: number): Observable<IDonor> {
        return super.getById(id).pipe(
            map((donor) => {
                // Fixes display of date in UI
                if (donor.DonorBasicInfo?.Dob) {
                    donor.DonorBasicInfo.Dob = (donor.DonorBasicInfo.Dob as any as string).replace('Z', '') as any;
                }
                return donor;
            }),
        );
    }
}
