import {
    ApplicationWhoAreYouDynamicControls,
    IApplicationWhoAreYouDynamicControlsParameters,
} from '../form-controls/application-who-are-you.form-controls';
import { IApplicationWhoAreYou } from '../interfaces/application-who-are-you';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    InputTypes,
    noZeroRequiredValidator,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { MetaItem } from '@mt-ng2/base-service';
import createMetaItemArrayOfnOptions from '../../../donor-portal/common/create-array-of-numeric-options.library';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IAncestry } from '@model/interfaces/ancestry';
import { FormYesNoButtonComponent } from '@common/components/form-yes-no-button.component';
import { getMetaItemValue } from '@mt-ng2/common-functions';

export class AdminApplicationWhoAreYouDynamicControlsPartial extends ApplicationWhoAreYouDynamicControls {
    constructor(
        metaData: IPersonalInformationMetaData,
        applicationwhoareyouPartial?: IApplicationWhoAreYou,
        additionalParameters?: IApplicationWhoAreYouDynamicControlsParameters,
        ancestries?: IAncestry[],
    ) {
        super(applicationwhoareyouPartial, additionalParameters);

        (<DynamicField>this.Form.BirthControlTimespan) = new DynamicField({
            formGroup: this.formGroup,
            label: 'How long have you been on this method of birth control?',
            name: 'BirthControlTimespan',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(100)],
            validators: { maxlength: 100, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('BirthControlTimespan') &&
                applicationwhoareyouPartial.BirthControlTimespan !== null
                    ? applicationwhoareyouPartial.BirthControlTimespan.toString()
                    : '',
        });
        (<DynamicField>this.Form.BirthControlTypeId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Birth Control Type',
            name: 'BirthControlTypeId',
            options: metaData.BirthControlTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.BirthControlTypeId) || null,
        });
        (<DynamicField>this.Form.BodyStructureId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Body Structure',
            name: 'BodyStructureId',
            options: metaData.BodyStructures,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.BodyStructureId) || null,
        });
        (<DynamicField>this.Form.CharacterDescription) = new DynamicField({
            formGroup: this.formGroup,
            label: 'How would a family member or friend describe your character?',
            name: 'CharacterDescription',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(100), Validators.maxLength(3000)],
            validators: { minlength: 100, maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('CharacterDescription') &&
                applicationwhoareyouPartial.CharacterDescription !== null
                    ? applicationwhoareyouPartial.CharacterDescription.toString()
                    : '',
        });
        (<DynamicField>this.Form.CollegeGpaId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'College Gpa',
            name: 'CollegeGpaId',
            options: metaData.Gpas,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.CollegeGpaId) || null,
        });
        (<DynamicField>this.Form.CollegeMajor) = new DynamicField({
            formGroup: this.formGroup,
            label: 'College Major',
            name: 'CollegeMajor',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(100)],
            validators: { maxlength: 100 },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('CollegeMajor') &&
                applicationwhoareyouPartial.CollegeMajor !== null
                    ? applicationwhoareyouPartial.CollegeMajor.toString()
                    : '',
        });
        (<DynamicField>this.Form.EducationCompletedTypeId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Highest Level of Education Completed',
            name: 'EducationCompletedTypeId',
            options: metaData.EducationCompletedTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.EducationCompletedTypeId) || null,
        });
        (<DynamicField>this.Form.ExerciseRoutine) = new DynamicField({
            formGroup: this.formGroup,
            label: 'What is your exercise routine?',
            name: 'ExerciseRoutine',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('ExerciseRoutine') &&
                applicationwhoareyouPartial.ExerciseRoutine !== null
                    ? applicationwhoareyouPartial.ExerciseRoutine.toString()
                    : '',
        });
        (<DynamicField>this.Form.EyeColorId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Eye Color',
            name: 'EyeColorId',
            options: metaData.EyeColors,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.EyeColorId) || null,
        });
        (<DynamicField>this.Form.Favorites) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Favorite (Books, Food, Music)',
            name: 'Favorites',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(100), Validators.maxLength(3000)],
            validators: { minlength: 100, maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('Favorites') &&
                applicationwhoareyouPartial.Favorites !== null
                    ? applicationwhoareyouPartial.Favorites.toString()
                    : '',
        });
        (<DynamicField>this.Form.Goals) = new DynamicField({
            formGroup: this.formGroup,
            label: 'What would some of your personal and/or career goals be?',
            name: 'Goals',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(100), Validators.maxLength(3000)],
            validators: { minlength: 100, maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial && applicationwhoareyouPartial.hasOwnProperty('Goals') && applicationwhoareyouPartial.Goals !== null
                    ? applicationwhoareyouPartial.Goals.toString()
                    : '',
        });
        (<DynamicField>this.Form.HairTextureId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Hair Texture',
            name: 'HairTextureId',
            options: metaData.HairTextures,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.HairTextureId) || null,
        });
        (<DynamicField>this.Form.HasBeenPregnant).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasGivenBirth).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasPlasticSurgery).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasPreviouslyDonatedEggs).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HighSchoolGpaId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'High School Gpa',
            name: 'HighSchoolGpaId',
            options: metaData.Gpas,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.HighSchoolGpaId) || null,
        });
        (<DynamicField>this.Form.HowManyLiveBirths) = new DynamicField({
            formGroup: this.formGroup,
            label: '',
            name: 'HowManyLiveBirths',
            options: createMetaItemArrayOfnOptions(10),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.min(0), Validators.required],
            validators: { min: 0, required: true },
            value: applicationwhoareyouPartial.HowManyLiveBirths,
        });
        (<DynamicField>this.Form.HowManyLiveBirths).options.unshift(new MetaItem(0, '0'));

        (<DynamicField>this.Form.IsCurrentlyListedOnAnotherEggDonorRoster).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.NaturalHairColorId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Natural Hair Color',
            name: 'NaturalHairColorId',
            options: metaData.HairColors,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.NaturalHairColorId) || null,
        });
        (<DynamicField>this.Form.NumberOfChildren) = new DynamicField({
            formGroup: this.formGroup,
            label: 'How many children have you had?',
            name: 'NumberOfChildren',
            options: createMetaItemArrayOfnOptions(10),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationwhoareyouPartial.NumberOfChildren,
        });
        (<DynamicField>this.Form.NumberOfEggDonations) = new DynamicField({
            formGroup: this.formGroup,
            label: '',
            name: 'NumberOfEggDonations',
            options: createMetaItemArrayOfnOptions(6),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: applicationwhoareyouPartial.NumberOfEggDonations,
        });
        (<DynamicField>this.Form.NumberOfEggDonations).options.push(new MetaItem(7, '> 6'));
        (<DynamicField>this.Form.OtherBirthControlMethod) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Other Birth Control Method',
            name: 'OtherBirthControlMethod',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(100)],
            validators: { maxlength: 100, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('OtherBirthControlMethod') &&
                applicationwhoareyouPartial.OtherBirthControlMethod !== null
                    ? applicationwhoareyouPartial.OtherBirthControlMethod.toString()
                    : '',
        });
        (<DynamicField>this.Form.OtherCommentsForTweb) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Is there anything else you want to say?',
            name: 'OtherCommentsForTweb',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
                scale: null,
            }),
            validation: [Validators.maxLength(500)],
            validators: { maxlength: 500 },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('OtherCommentsForTweb') &&
                applicationwhoareyouPartial.OtherCommentsForTweb !== null
                    ? applicationwhoareyouPartial.OtherCommentsForTweb.toString()
                    : '',
        });
        (<DynamicField>this.Form.OtherEggDonorRosters) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Where?',
            name: 'OtherEggDonorRosters',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(250)],
            validators: { maxlength: 100, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('OtherEggDonorRosters') &&
                applicationwhoareyouPartial.OtherEggDonorRosters !== null
                    ? applicationwhoareyouPartial.OtherEggDonorRosters.toString()
                    : '',
        });
        (<DynamicField>this.Form.PlasticSurgeryTypeAndYear) = new DynamicField({
            formGroup: this.formGroup,
            label: 'What surgery and year?',
            name: 'PlasticSurgeryTypeAndYear',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(250)],
            validators: { maxlength: 250, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('PlasticSurgeryTypeAndYear') &&
                applicationwhoareyouPartial.PlasticSurgeryTypeAndYear !== null
                    ? applicationwhoareyouPartial.PlasticSurgeryTypeAndYear.toString()
                    : '',
        });
        (<DynamicField>this.Form.RaceId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Race',
            name: 'RaceId',
            options: metaData.Races,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.RaceId) || null,
        });
        (<DynamicField>this.Form.SkinTypeId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Skin Type',
            name: 'SkinTypeId',
            options: metaData.SkinTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.SkinTypeId) || null,
        });
        (<DynamicField>this.Form.TalentsAbilities) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Talents/Abilities',
            name: 'TalentsAbilities',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(100), Validators.maxLength(3000)],
            validators: { minlength: 100, maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('TalentsAbilities') &&
                applicationwhoareyouPartial.TalentsAbilities !== null
                    ? applicationwhoareyouPartial.TalentsAbilities.toString()
                    : '',
        });
        (<DynamicField>this.Form.WhyInterestedInBecomingEggDonor) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Why are you interested in becoming an egg donor?',
            name: 'WhyInterestedInBecomingEggDonor',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(100), Validators.maxLength(3000)],
            validators: { minlength: 100, maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('WhyInterestedInBecomingEggDonor') &&
                applicationwhoareyouPartial.WhyInterestedInBecomingEggDonor !== null
                    ? applicationwhoareyouPartial.WhyInterestedInBecomingEggDonor.toString()
                    : '',
        });

        (<DynamicField>this.Form.OtherAncestryMother).label = 'Mother\'s Ancestry';
        (<DynamicField>this.Form.OtherAncestryMother).validation = [Validators.required, Validators.maxLength(250)];
        (<DynamicField>this.Form.OtherAncestryMother).validators = { maxlength: 250, required: true };

        (<DynamicField>this.Form.OtherAncestryFather).label = 'Fathers\'s Ancestry';
        (<DynamicField>this.Form.OtherAncestryFather).validation = [Validators.required, Validators.maxLength(250)];
        (<DynamicField>this.Form.OtherAncestryFather).validators = { maxlength: 250, required: true };

        (<DynamicField>this.Form.ApplicationFatherAncestries) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Father Ancestries',
            name: 'ApplicationFatherAncestries',
            options: ancestries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: this.getAncestriesMultiselectValue(applicationwhoareyouPartial.FatherAncestries, ancestries),
        });
        (<DynamicField>this.Form.ApplicationMotherAncestries) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Mother Ancestries',
            name: 'ApplicationMotherAncestries',
            options: ancestries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: this.getAncestriesMultiselectValue(applicationwhoareyouPartial.MotherAncestries, ancestries),
        });
        (<DynamicField>this.Form.HasBeenVictimOfSeriousTraumaticEvent) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor has been the victim of a serious traumatic event',
            name: 'HasBeenVictimOfSeriousTraumaticEvent',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasBeenVictimOfSeriousTraumaticEvent') &&
                applicationwhoareyouPartial.HasBeenVictimOfSeriousTraumaticEvent !== null
                    ? applicationwhoareyouPartial.HasBeenVictimOfSeriousTraumaticEvent
                    : false,
        });
        (<DynamicField>this.Form.HasDepression) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor or an immediate family member has experienced or been diagnosed with serious depression lasting a month or more',
            name: 'HasDepression',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasDepression') &&
                applicationwhoareyouPartial.HasDepression !== null
                    ? applicationwhoareyouPartial.HasDepression
                    : false,
        });
        (<DynamicField>this.Form.HasMoodSwings) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor or an immediate family member has experienced or been diagnosed with severe mood swings ranging from blue to extraordinarily happy',
            name: 'HasMoodSwings',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasMoodSwings') &&
                applicationwhoareyouPartial.HasMoodSwings !== null
                    ? applicationwhoareyouPartial.HasMoodSwings
                    : false,
        });
        (<DynamicField>this.Form.HasAnxiety) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor or an immediate family member has experienced or been diagnosed with anxiety',
            name: 'HasAnxiety',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasAnxiety') &&
                applicationwhoareyouPartial.HasAnxiety !== null
                    ? applicationwhoareyouPartial.HasAnxiety
                    : false,
        });
        (<DynamicField>this.Form.HasSchizophreniaSigns) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor or an immediate family member has had thoughts of being watched or controlled, hearing voices, or having trouble knowing what is real or not',
            name: 'HasSchizophreniaSigns',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasSchizophreniaSigns') &&
                applicationwhoareyouPartial.HasSchizophreniaSigns !== null
                    ? applicationwhoareyouPartial.HasSchizophreniaSigns
                    : false,
        });
        (<DynamicField>this.Form.HasBeenUnderPsychiatristCare) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor has been under the care of a psychiatrist',
            name: 'HasBeenUnderPsychiatristCare',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasBeenUnderPsychiatristCare') &&
                applicationwhoareyouPartial.HasBeenUnderPsychiatristCare !== null
                    ? applicationwhoareyouPartial.HasBeenUnderPsychiatristCare
                    : false,
        });
        (<DynamicField>this.Form.HasBeenInRehab) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor has received treatment for drug/alcohol abuse',
            name: 'HasBeenInRehab',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasBeenInRehab') &&
                applicationwhoareyouPartial.HasBeenInRehab !== null
                    ? applicationwhoareyouPartial.HasBeenInRehab
                    : false,
        });
        (<DynamicField>this.Form.HasFamilyBirthDefects) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Donor has known genetic conditions or birth defects in her family',
            name: 'HasFamilyBirthDefects',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasFamilyBirthDefects') &&
                applicationwhoareyouPartial.HasFamilyBirthDefects !== null
                    ? applicationwhoareyouPartial.HasFamilyBirthDefects
                    : false,
        });
        (<DynamicLabel>this.View.HasBeenVictimOfSeriousTraumaticEvent) = new DynamicLabel({
            label: 'Donor has been the victim of a serious traumatic event',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('HasBeenVictimOfSeriousTraumaticEvent') &&
                applicationwhoareyouPartial.HasBeenVictimOfSeriousTraumaticEvent !== null
                    ? applicationwhoareyouPartial.HasBeenVictimOfSeriousTraumaticEvent
                    : false,
        });
        (<DynamicField>this.Form.SeriousTraumaticEventId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Serious Traumatic Event',
            name: 'SeriousTraumaticEventId',
            options: metaData.SeriousTraumaticEvents,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.SeriousTraumaticEventId) || null,
        });
        (<DynamicField>this.Form.FamilyBirthDefects) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Family Genetic Conditions or Birth Defects',
            name: 'FamilyBirthDefects',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(250)],
            validators: { maxlength: 100, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('FamilyBirthDefects') &&
                applicationwhoareyouPartial.FamilyBirthDefects !== null
                    ? applicationwhoareyouPartial.FamilyBirthDefects.toString()
                    : '',
        });
        (<DynamicField>this.Form.CurrentOccupation) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Current Occupation',
            name: 'CurrentOccupation',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(100)],
            validators: { required: true, maxlength: 100 },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('CurrentOccupation') &&
                applicationwhoareyouPartial.CurrentOccupation !== null
                    ? applicationwhoareyouPartial.CurrentOccupation.toString()
                    : '',
        });
        (<DynamicLabel>this.View.HasDepression).label =
            'Donor or an immediate family member has experienced or been diagnosed with serious depression lasting a month or more';
        (<DynamicLabel>this.View.HasMoodSwings).label =
            'Donor or an immediate family member has experienced or been diagnosed with severe mood swings ranging from blue to extraordinarily happy';
        (<DynamicLabel>this.View.HasAnxiety).label = 'Donor or an immediate family member has experienced or been diagnosed with anxiety';
        (<DynamicLabel>this.View.HasSchizophreniaSigns).label =
            'Donor or an immediate family member has had thoughts of being watched or controlled, hearing voices, or having trouble knowing what is real or not';
        (<DynamicLabel>this.View.HasBeenUnderPsychiatristCare).label = 'Donor has been under the care of a psychiatrist';
        (<DynamicLabel>this.View.HasBeenInRehab).label = 'Donor has received treatment for drug/alcohol abuse';
        (<DynamicLabel>this.View.HasFamilyBirthDefects).label = 'Donor has known genetic conditions or birth defects in her family';
        (<DynamicLabel>this.View.FamilyBirthDefects).label = 'Family Genetic Conditions or Birth Defects';
        (<DynamicLabel>this.View.WhyInterestedInBecomingEggDonor).label = 'Why are you interested in becoming an egg donor?';
    }

    getAncestriesMultiselectValue(value: IAncestry[], options: any[]): number[] {
        const ancestryIds = value.map((itm) => itm.Id);
        return options?.filter((itm) => ancestryIds.includes(itm.Id)).map((itm) => itm.Id);
    }
}
