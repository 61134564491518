import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IApplicationPreliminaryHistory } from '../interfaces/application-preliminary-history';

export interface IApplicationPreliminaryHistoryDynamicControlsParameters {
    formGroup?: string;
}

export class ApplicationPreliminaryHistoryDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private applicationpreliminaryhistory?: IApplicationPreliminaryHistory, additionalParameters?: IApplicationPreliminaryHistoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ApplicationPreliminaryHistory';

        this.Form = {
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('Complete') && this.applicationpreliminaryhistory.Complete !== null ? this.applicationpreliminaryhistory.Complete : false,
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.DateModified || null,
            }),
            DateOfStdTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Of Std Treatment',
                name: 'DateOfStdTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('DateOfStdTreatment') && this.applicationpreliminaryhistory.DateOfStdTreatment !== null ? this.applicationpreliminaryhistory.DateOfStdTreatment.toString() : '',
            }),
            HasAdoptedParents: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Adopted Parents',
                name: 'HasAdoptedParents',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('HasAdoptedParents') && this.applicationpreliminaryhistory.HasAdoptedParents !== null ? this.applicationpreliminaryhistory.HasAdoptedParents : false,
            }),
            HasHiv: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Hiv',
                name: 'HasHiv',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('HasHiv') && this.applicationpreliminaryhistory.HasHiv !== null ? this.applicationpreliminaryhistory.HasHiv : false,
            }),
            HasStd: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Std',
                name: 'HasStd',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('HasStd') && this.applicationpreliminaryhistory.HasStd !== null ? this.applicationpreliminaryhistory.HasStd : false,
            }),
            IsAdopted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Adopted',
                name: 'IsAdopted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('IsAdopted') && this.applicationpreliminaryhistory.IsAdopted !== null ? this.applicationpreliminaryhistory.IsAdopted : false,
            }),
            StdName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Std Name',
                name: 'StdName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('StdName') && this.applicationpreliminaryhistory.StdName !== null ? this.applicationpreliminaryhistory.StdName.toString() : '',
            }),
        };

        this.View = {
            Complete: new DynamicLabel(
                'Complete',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('Complete') && this.applicationpreliminaryhistory.Complete !== null ? this.applicationpreliminaryhistory.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Submitted: new DynamicLabel(
                'Submitted',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('Submitted') && this.applicationpreliminaryhistory.Submitted !== null ? this.applicationpreliminaryhistory.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateOfStdTreatment: new DynamicLabel(
                'Date Of Std Treatment',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('DateOfStdTreatment') && this.applicationpreliminaryhistory.DateOfStdTreatment !== null ? this.applicationpreliminaryhistory.DateOfStdTreatment.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasAdoptedParents: new DynamicLabel(
                'Has Adopted Parents',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('HasAdoptedParents') && this.applicationpreliminaryhistory.HasAdoptedParents !== null ? this.applicationpreliminaryhistory.HasAdoptedParents : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasHiv: new DynamicLabel(
                'Has Hiv',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('HasHiv') && this.applicationpreliminaryhistory.HasHiv !== null ? this.applicationpreliminaryhistory.HasHiv : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasStd: new DynamicLabel(
                'Has Std',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('HasStd') && this.applicationpreliminaryhistory.HasStd !== null ? this.applicationpreliminaryhistory.HasStd : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsAdopted: new DynamicLabel(
                'Is Adopted',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('IsAdopted') && this.applicationpreliminaryhistory.IsAdopted !== null ? this.applicationpreliminaryhistory.IsAdopted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            StdName: new DynamicLabel(
                'Std Name',
                this.applicationpreliminaryhistory && this.applicationpreliminaryhistory.hasOwnProperty('StdName') && this.applicationpreliminaryhistory.StdName !== null ? this.applicationpreliminaryhistory.StdName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
