import { ApplicationIssueDynamicControls, IApplicationIssueDynamicControlsParameters } from '../form-controls/application-issue.form-controls';
import { IApplicationIssue } from '../interfaces/application-issue';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, LabelPositions } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';

export class ApplicationIssueDynamicControlsPartial extends ApplicationIssueDynamicControls {
    constructor(applicationissuePartial?: IApplicationIssue, additionalParameters?: IApplicationIssueDynamicControlsParameters) {
        super(applicationissuePartial, additionalParameters);

        (<DynamicField>this.Form.Surgeries).label = '';
        (<DynamicField>this.Form.OtherIssues).label = '';
        (<DynamicField>this.Form.CurrentMedications).label = '';
        (<DynamicField>this.Form.Surgeries).validators = { minlength: 1, maxlength: 1000 };
        (<DynamicField>this.Form.CurrentMedications).validators = { minlength: 1, maxlength: 1000 };
        (<DynamicField>this.Form.CurrentMedications).validation = [Validators.required, Validators.minLength(1), Validators.maxLength(1000)];
        (<DynamicField>this.Form.Surgeries).validation = [Validators.required, Validators.minLength(1), Validators.maxLength(1000)];
        (<DynamicField>this.Form.Surgeries).label = 'Which medications?';
        (<DynamicField>this.Form.CurrentMedications).labelPosition = { position: LabelPositions.Hidden, colsForLabel: null };
        (<DynamicField>this.Form.SelectedIsTakingMedication) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Taking Medications?',
            name: 'SelectedIsTakingMedication',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationissuePartial.IsTakingMedication === null ? null : applicationissuePartial.IsTakingMedication ? 1 : 2,
        });

        (<DynamicField>this.Form.SelectedHasSurgeries) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Has Surgeries?',
            name: 'SelectedHasSurgeries',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationissuePartial.HasSurgeries === null ? null : applicationissuePartial.HasSurgeries ? 1 : 2,
        });
    }
}
