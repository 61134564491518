<div *ngIf="whoAreYou.Id > 0 && !isEditing">
    <div class="form-section-border">
        <h3>
            Who Are You
            <span class="pull-right" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h3>
    </div>

    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>

    <div *ngIf="motherAncestries && motherAncestries.length">
        <b style="padding-left: 5px">Other Mother Ancestries:</b>
        <ul>
            <li *ngFor="let motherAncestry of motherAncestries">{{ motherAncestry.Name }}</li>
        </ul>
    </div>
    <div *ngIf="fatherAncestries && fatherAncestries.length">
        <b style="padding-left: 5px">Other Father Ancestries:</b>
        <ul>
            <li *ngFor="let fatherAncestry of motherAncestries">{{ fatherAncestry.Name }}</li>
        </ul>
    </div>
    <div *ngIf="applicationChildrenDetails && applicationChildrenDetails.length">
        <strong style="padding-left: 5px">Children Details:</strong>
        <ul>
            <li *ngFor="let item of applicationChildrenDetails; let i = index">
                <h5 style="padding-left: 5px">
                    <strong>Child - {{ i + 1 }}</strong>
                </h5>
                <p style="padding-left: 15px">Year Of Birth: {{ item.YearOfBirth }}</p>
                <p style="padding-left: 15px">Sex: {{ item.Gender.Name }}</p>
                <p style="padding-left: 15px">Are you currently breastfeeding: {{ item.IsBreastFeed }}</p>
            </li>
        </ul>
    </div>
</div>
<div *ngIf="isEditing">
    <application-who-are-you-form
        [whoAreYou]="whoAreYou"
        [whoAreYouMetaItems]="whoAreYouMetaItems"
        (onFinish)="onEditFinished($event)"
    ></application-who-are-you-form>
</div>
