import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';
import { Observable } from 'rxjs';

export const emptyApplicationBasicInfo: IApplicationBasicInfo = {
    Complete: null,
    DateCreated: null,
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationBasicInfoService extends BaseService<IApplicationBasicInfo> {
    constructor(public http: HttpClient) {
        super('/application-basic-infos', http);
    }

    getEmptyApplicationBasicInfo(): IApplicationBasicInfo {
        return { ...emptyApplicationBasicInfo };
    }
}
