import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';
import { ClinicRoutingModule } from './clinic-routing.module';
import { ClinicAddComponent } from './clinic-add/clinic-add.component';
import { ClinicBasicInfoComponent } from './clinic-basic-info/clinic-basic-info.component';
import { ClinicDetailComponent } from './clinic-detail/clinic-detail.component';
import { ClinicsComponent } from './clinic-list/clinics.component';
import { ClinicHeaderComponent } from './clinic-header/clinic-header.component';
import { ClinicEntityComponent } from './clinic-entity/clinic-entity.component';
import { MtPhoneControlModule } from '@mt-ng2/phone-control';
import { ClinicVerificationBasicInfoComponent } from './clinic-verification-basic-info/clinic-verification-basic-info.component';
import { ClinicEmbryologistsComponent } from './add-embryologists/clinic-embryologists.component';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { DeleteClinicComponent } from './clinic-list/delete-clinic.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { ClinicDonorMonitoringComponent } from './clinic-donor-monitoring/clinic-donor-monitoring.component';

@NgModule({
    declarations: [
        ClinicsComponent,
        ClinicHeaderComponent,
        ClinicAddComponent,
        ClinicDetailComponent,
        ClinicBasicInfoComponent,
        ClinicEntityComponent,
        ClinicVerificationBasicInfoComponent,
        ClinicEmbryologistsComponent,
        DeleteClinicComponent,
        ClinicDonorMonitoringComponent,
    ],
    imports: [SharedModule, ClinicRoutingModule, MtPhoneControlModule, TypeAheadModule, ModalModule],
})
export class ClinicModule {}
