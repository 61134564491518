import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { ApplicationWhoAreYouDynamicControlsPartial } from '@model/partials/application-who-are-you-partial.form-controls';
import { ApplicationWhoAreYouService } from '../../../services/application-who-are-you.service';
import { BirthControlTypes } from '@model/enums/birth-control-types.enum';
import { IAncestry } from '@model/interfaces/ancestry';
import { AncestryTypes } from '@model/enums/ancestry-type.enum';
import { ApplicationService } from '../../../../services/application.service';
import { YesNo } from '@model/enums/yes-no.enum';
import { AdminApplicationWhoAreYouDynamicControlsPartial } from '@model/partials/admin-application-who-are-you-partial.form-controls';
import { IMetaItem } from '@mt-ng2/base-service';
import { IApplicationWhoAreYouChildren } from '@model/interfaces/application-who-are-you-children';
import { forkJoin } from 'rxjs';
import { GenderService } from '../../../../../recipients/outcomes/service/gender.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'application-who-are-you-form',
    templateUrl: './who-are-you-form.component.html',
})
export class ApplicationWhoAreYouFormComponent implements OnInit {
    @Input() whoAreYouMetaItems: IPersonalInformationMetaData;
    @Input() whoAreYou: IApplicationWhoAreYou;
    @Output() onFinish: EventEmitter<IApplicationWhoAreYou> = new EventEmitter<IApplicationWhoAreYou>();
    // abstract controls
    abstractApplicationWhoAreYouControls: any;

    whoAreYouForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    showIsBreastFeed = {};
    childrenDetail = {};
    lastTwentyYearsArray: IMetaItem[] = this.getLastTwentyYearsArray();
    sexOptions: IMetaItem[] = [];
    breastFeedingOptions: IMetaItem[] = [
        { Id: 0, Name: 'Y' },
        { Id: 1, Name: 'N' },
    ];

    // UI vars
    showSurgeryTypeAndYear = false;
    showOtherBirthControlMethod = false;
    showBirthControlTimespan = false;
    showHowManyLiveBirths = false;
    showNumberOfChildren = false;
    showNumberOfEggsDonated = false;
    showOtherEggRosters = false;
    showOtherMotherAncestries = false;
    showOtherFatherAncestries = false;
    showSeriousTraumaticEvent = false;
    showFamilyBirthDefects = false;

    // metadata: IPersonalInformationMetaData;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private whoAreYouService: ApplicationWhoAreYouService,
        private applicationService: ApplicationService,
        private genderService: GenderService,
    ) {}

    ngOnInit(): void {
        this.setShowIsBreastFeed();
        this.setChildrenDetail();
        this.createForm();

        forkJoin({ sexOptions: this.genderService.getAll() }).subscribe(({ sexOptions }) => {
            this.sexOptions = [...sexOptions];
            if (this.whoAreYou.ApplicationWhoAreYouChildrens.length) {
                this.whoAreYou.ApplicationWhoAreYouChildrens.forEach((c) => Object.assign(this.childrenDetail[c.ChildId], c));
                for (let index = 0; index < this.whoAreYou.ApplicationWhoAreYouChildrens.length; index++) {
                    let childDetails = this.getChildDetail(index);

                    let sexOptionsId = this.sexOptions.find((s) => s.Id === childDetails.Sex).Id;
                    let childYearOfBirth = childDetails.YearOfBirth.toString();
                    let yearOfBirthOptionId = this.lastTwentyYearsArray.find((y) => y.Name === childYearOfBirth).Id;

                    let isChildBreastFeed = childDetails.IsBreastFeed ? 'Y' : 'N';
                    let isBreastFeedOptionId = this.breastFeedingOptions.find((b) => b.Name === isChildBreastFeed).Id;

                    if (!this.isChildYoungerThatFive(childDetails.YearOfBirth)) {
                        this.showIsBreastFeed[index] = false;
                        childDetails.IsBreastFeed = false;
                    }
                    this.addApplicationChildControl(yearOfBirthOptionId, sexOptionsId, isBreastFeedOptionId);
                }
            }
        });
    }

    createForm(): void {
        this.setFormFieldUi();
        this.getControls();
        this.whoAreYouForm = this.assignFormGroups();
        this.cdr.detectChanges();
        this.formCreated = true;
    }

    getControls(): void {
        this.abstractApplicationWhoAreYouControls = new AdminApplicationWhoAreYouDynamicControlsPartial(
            this.whoAreYouMetaItems,
            this.whoAreYou,
            {
                birthControlTypes: this.whoAreYouMetaItems.BirthControlTypes,
                bodyStructures: this.whoAreYouMetaItems.BodyStructures,
                collegeGpas: this.whoAreYouMetaItems.Gpas,
                educationCompletedTypes: this.whoAreYouMetaItems.EducationCompletedTypes,
                eyeColors: this.whoAreYouMetaItems.EyeColors,
                formGroup: 'ApplicationWhoAreYou',
                hairTextures: this.whoAreYouMetaItems.HairTextures,
                highSchoolGpas: this.whoAreYouMetaItems.Gpas,
                naturalHairColors: this.whoAreYouMetaItems.HairColors,
                races: this.whoAreYouMetaItems.Races,
                seriousTraumaticEvents: this.whoAreYouMetaItems.SeriousTraumaticEvents,
                skinTypes: this.whoAreYouMetaItems.SkinTypes,
            },
            this.whoAreYouMetaItems.Ancestries,
        ).Form;
    }

    setFormFieldUi(): void {
        this.showSurgeryTypeAndYear = this.whoAreYou.HasPlasticSurgery;
        this.showOtherBirthControlMethod = this.whoAreYou.BirthControlTypeId === BirthControlTypes.Other ? true : false;
        this.showBirthControlTimespan = this.whoAreYou.BirthControlTypeId === BirthControlTypes.None ? false : true;
        this.showHowManyLiveBirths = this.whoAreYou.HasBeenPregnant;
        this.showNumberOfChildren = this.whoAreYou.HasGivenBirth;
        this.showNumberOfEggsDonated = this.whoAreYou.HasPreviouslyDonatedEggs;
        this.showOtherEggRosters = this.whoAreYou.IsCurrentlyListedOnAnotherEggDonorRoster;
        const otherAncestry = this.whoAreYouMetaItems.Ancestries.find((a) => a.Id === AncestryTypes.Other);
        this.showOtherMotherAncestries = this.whoAreYou.FatherAncestries.includes(otherAncestry);
        this.showOtherFatherAncestries = this.whoAreYou.MotherAncestries.includes(otherAncestry);
        this.showSeriousTraumaticEvent = this.whoAreYou.HasBeenVictimOfSeriousTraumaticEvent;
        this.showFamilyBirthDefects = this.whoAreYou.HasFamilyBirthDefects;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationWhoAreYou: this.fb.group({}),
            ApplicationWhoAreYouChildrenArray: this.fb.array([]),
        });
    }

    onPlasticSurgeryValueChange(value: boolean): void {
        this.showSurgeryTypeAndYear = value;
    }

    onBirthControlTypeValueChange(value: number): void {
        this.showOtherBirthControlMethod = value === BirthControlTypes.Other ? true : false;
        this.showBirthControlTimespan = value === BirthControlTypes.None ? false : true;
    }

    onHasBeenPreganantValueChange(value: boolean): void {
        this.showHowManyLiveBirths = value;
    }

    onHasGivenBirthValueChange(value: boolean): void {
        this.showNumberOfChildren = value;
    }

    onPreviouslyDonatedValueChange(value: boolean): void {
        this.showNumberOfEggsDonated = value;
    }

    onListOnAnotherRosterValueChange(value: boolean): void {
        this.showOtherEggRosters = value;
    }

    onMotherAncestryValueChange(value: number[]): void {
        this.showOtherMotherAncestries = value.includes(AncestryTypes.Other);
    }

    onFatherAncestryValueChange(value: number[]): void {
        this.showOtherFatherAncestries = value.includes(AncestryTypes.Other);
    }

    onHasBeenVictimOfSeriousTraumaticEventChange(value: boolean): void {
        this.showSeriousTraumaticEvent = value;
    }

    onHasFamilyBirthDefectsChange(value: boolean): void {
        this.showFamilyBirthDefects = value;
    }

    formSubmitted(): void {
        if (this.whoAreYouForm.valid) {
            Object.assign(this.whoAreYou, this.whoAreYouForm.value.ApplicationWhoAreYou);
            this.whoAreYou.ApplicationWhoAreYouChildrens = [...this.getChildrenDetail()];
            if (!this.whoAreYou.HasBeenVictimOfSeriousTraumaticEvent) {
                this.whoAreYou.SeriousTraumaticEventId = null;
            }
            if (!this.whoAreYou.HasFamilyBirthDefects) {
                this.whoAreYou.FamilyBirthDefects = null;
            }
            this.whoAreYou.FatherAncestries = this.parseAncestries(this.whoAreYouForm.value.ApplicationWhoAreYou.ApplicationFatherAncestries);
            this.whoAreYou.MotherAncestries = this.parseAncestries(this.whoAreYouForm.value.ApplicationWhoAreYou.ApplicationMotherAncestries);
            if (!this.showBirthControlTimespan) {
                this.whoAreYou.BirthControlTimespan = '';
            }
            this.whoAreYouService.updateWithFks(this.whoAreYou).subscribe((resp) => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.whoAreYouForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    getChildrenDetail(): IApplicationWhoAreYouChildren[] {
        let result: IApplicationWhoAreYouChildren[] = [];
        for (const key in this.childrenDetail) {
            if (Object.prototype.hasOwnProperty.call(this.childrenDetail, key)) {
                const element = this.childrenDetail[key];
                result.push(element);
            }
        }
        return result;
    }

    parseAncestries(ancestryIds: number[]): IAncestry[] {
        return this.whoAreYouMetaItems.Ancestries.filter((a) => ancestryIds.includes(a.Id));
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onFinish.emit(null);
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Who are you saved successfully.');
        this.enableDoubleClick();
        this.onFinish.emit(this.whoAreYou);
    }

    get ApplicationWhoAreYou(): FormGroup {
        return this.whoAreYouForm.get('ApplicationWhoAreYou') as FormGroup;
    }

    get ApplicationWhoAreYouChildrenArray(): FormArray {
        return this.whoAreYouForm.get('ApplicationWhoAreYouChildrenArray') as FormArray;
    }

    get ApplicationWhoAreYouChildrenFormGroup(): FormGroup[] {
        let group = [];
        for (const iterator of this.ApplicationWhoAreYouChildrenArray.controls) {
            group.push(iterator as FormGroup);
        }
        return group;
    }

    getApplicationChildControlLength(): number {
        return this.ApplicationWhoAreYouChildrenArray.controls.length;
    }

    clearApplicationChildControl(): void {
        this.ApplicationWhoAreYouChildrenArray.controls = [];
    }

    clearApplicationChildControlPop(): void {
        this.ApplicationWhoAreYouChildrenArray.controls.pop();
    }

    addApplicationChildControl(yearOfBirthOptionId = 0, sexOptionsId = 1, isBreastFeedOptionId = 0): void {
        this.ApplicationWhoAreYouChildrenArray.push(
            this.getApplicationWhoAreYouChildrenFormGroup(yearOfBirthOptionId, sexOptionsId, isBreastFeedOptionId),
        );
    }

    getApplicationWhoAreYouChildrenFormGroup(yearOfBirthOptionId: number, sexOptionsId: number, isBreastFeedOptionId: number): FormGroup {
        return this.fb.group({
            ChildGender: new DynamicField({
                formGroup: 'ApplicationWhoAreYouChildrenArray',
                label: 'Sex',
                name: 'ChildGender',
                options: this.sexOptions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: sexOptionsId !== undefined && sexOptionsId !== null ? sexOptionsId : 1,
            }),
            IsBreastFeed: new DynamicField({
                formGroup: 'ApplicationWhoAreYouChildrenArray',
                label: 'Are You Breastfeeding',
                name: 'IsBreastFeed',
                options: this.breastFeedingOptions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: isBreastFeedOptionId !== undefined && isBreastFeedOptionId !== null ? isBreastFeedOptionId : 0,
            }),
            YearOfChildBirth: new DynamicField({
                formGroup: 'ApplicationWhoAreYouChildrenArray',
                label: 'Year Of Birth',
                name: 'YearOfChildBirth',
                options: this.lastTwentyYearsArray,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: yearOfBirthOptionId !== undefined && yearOfBirthOptionId !== null ? yearOfBirthOptionId : 0,
            }),
        });
    }

    onHowManyLiveBirthsValueChange(liveBirthsValue: number): void {
        let numberOfApplicationChildControls = this.getApplicationChildControlLength();
        let indexStart = 0;
        if (numberOfApplicationChildControls === 0 || liveBirthsValue === 0) {
            this.clearApplicationChildControl();
        }

        if (liveBirthsValue < numberOfApplicationChildControls) {
            for (let index = liveBirthsValue; index < numberOfApplicationChildControls; index++) {
                this.clearApplicationChildControlPop();
                delete this.childrenDetail[index];
            }
            return;
        }

        if (liveBirthsValue > numberOfApplicationChildControls) {
            indexStart = numberOfApplicationChildControls;
        }

        for (let index = indexStart; index < liveBirthsValue; index++) {
            this.addApplicationChildControl();
            this.childrenDetail[index] = this.getEmptyApplicationWhoAreYouChildren(index);
            this.onYearOfChildBirthValueChange(0, index);
        }
    }

    onYearOfChildBirthValueChange(yearId: number, childIndex: number): void {
        this.ApplicationWhoAreYou.markAsDirty();
        let childDetail = this.getChildDetail(childIndex);
        let year = parseInt(this.lastTwentyYearsArray.filter((i) => i.Id === yearId)[0].Name, 10);
        childDetail.YearOfBirth = year;

        if (this.isChildYoungerThatFive(year)) {
            this.showIsBreastFeed[childIndex] = true;
            return;
        }

        this.showIsBreastFeed[childIndex] = false;
        childDetail.IsBreastFeed = false;
    }

    onChildGenderValueChange(value: number, index: number): void {
        this.ApplicationWhoAreYou.markAsDirty();
        let childDetail = this.getChildDetail(index);
        childDetail.Sex = this.sexOptions.filter((s) => s.Id === value)[0].Id;
    }

    onIsBreastFeedValueChange(value: number, index: number): void {
        this.ApplicationWhoAreYou.markAsDirty();
        let childDetail = this.getChildDetail(index);
        let breastFeedingValue = this.breastFeedingOptions.filter((b) => b.Id === value)[0].Name;
        childDetail.IsBreastFeed = breastFeedingValue === 'Y' ? true : false;
    }

    setShowIsBreastFeed(): void {
        if (this.whoAreYou?.HowManyLiveBirths) {
            for (let index = 0; index < this.whoAreYou.HowManyLiveBirths; index++) {
                this.showIsBreastFeed[index] = true;
            }
        }
    }

    setChildrenDetail(): void {
        if (this.whoAreYou?.HowManyLiveBirths) {
            for (let index = 0; index < this.whoAreYou.HowManyLiveBirths; index++) {
                this.childrenDetail[index] = this.getEmptyApplicationWhoAreYouChildren(index);
            }
        }
    }

    getChildDetail(index: number): IApplicationWhoAreYouChildren {
        return this.childrenDetail[index];
    }

    getEmptyApplicationWhoAreYouChildren(index: number): IApplicationWhoAreYouChildren {
        return {
            ApplicationWhoAreYousId: this.whoAreYou.Id,
            ChildId: index,
            DateCreated: new Date(),
            Id: 0,
            IsBreastFeed: true,
            Sex: 1,
            YearOfBirth: new Date().getFullYear(),
        };
    }

    getLastTwentyYearsArray(): IMetaItem[] {
        let currentYear = new Date().getFullYear();
        let result: IMetaItem[] = [];
        for (let index = 0; index <= 20; index++) {
            result.push({ Id: index, Name: (currentYear - index).toString() });
        }
        return result;
    }

    isChildYoungerThatFive(childBirthYear: number): boolean {
        let fiveYearsAgo = new Date().getFullYear() - 4;
        if (childBirthYear > fiveYearsAgo) {
            return true;
        }
        return false;
    }
}
