import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IReferralType } from '@model/interfaces/referral-type';
import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';
import {
    ApplicationReviewBasicInfoDynamicControlsPartial,
    IApplicationBasicInfoDynamicControlsParametersPartial,
} from '@model/partials/application-review-basic-info-partial.form-controls';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { IGender } from '@model/interfaces/gender';

export class AppBasicsCompleteDynamicConfig<T extends IApplicationBasicInfo> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private appBasicInfo: T,
        private heightUnits: IUnitsOfMeasurement[],
        private weightUnits: IUnitsOfMeasurement[],
        private referralTypes: IReferralType[],
        private countries: ICountryMetaItem[],
        private states: IStateMetaItem[],
        private genders: IGender[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IApplicationBasicInfoDynamicControlsParametersPartial = {
            countries: this.countries,
            heights: this.heightUnits,
            referralTypes: this.referralTypes,
            states: this.states,
            weights: this.weightUnits,
            genders: this.genders,
        };
        const dynamicControls = new ApplicationReviewBasicInfoDynamicControlsPartial(this.appBasicInfo, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'Address1',
                'Address2',
                'City',
                'StateCode',
                'Province',
                'CountryCode',
                'Zip',
                'Dob',
                'ApplicantPhoneNumber',
                'HasTextCommunication',
                'OptedOutOfEmails',
                'EmergencyContactName',
                'EmergencyContactNumber',
                'HeightUnitId',
                'Height',
                'WeightUnitId',
                'Weight',
                'ReferralTypeId',
                'Comment',
                'GenderId',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
