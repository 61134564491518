import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IApplicationPreliminaryHistory } from '@model/interfaces/application-preliminary-history';

export const emptyApplicationPreliminaryHistory: IApplicationPreliminaryHistory = {
    Complete: null,
    DateCreated: null,
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationPreliminaryHistoryService extends BaseService<IApplicationPreliminaryHistory> {
    constructor(public http: HttpClient) {
        super('/application-preliminary-histories', http);
    }

    getEmptyApplicationPreliminaryHistory(): IApplicationPreliminaryHistory {
        return { ...emptyApplicationPreliminaryHistory };
    }
}
