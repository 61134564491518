import { Component, Input, OnInit } from '@angular/core';

import { ApplicationDataService, ICurrentSection } from '../../services/application-data.service';
import { FormSections } from '@model/enums/form-sections.enum';
import { PreliminaryHistoryDynamicConfig } from './preliminary-history.dynamic-config';
import { IApplicationPreliminaryHistory } from '@model/interfaces/application-preliminary-history';
import { FormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ApplicationPreliminaryHistoryService } from '../../services/application-preliminary-history.service';
import { IApplicationValidationDTO } from '@model/interfaces/custom/application-validation-dto';
import { IApplication } from '@model/interfaces/application';
import { ApplicationService } from '../../../services/application.service';

@Component({
    selector: 'preliminary-history',
    styles: [
        `
            .adoption-tooltip .tooltiptext {
                color: red;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
            }
        `,
    ],
    templateUrl: './preliminary-history.component.html',
})
export class AppPreliminaryHistoryComponent implements OnInit {
    @Input() preliminaryHistory: IApplicationPreliminaryHistory;
    @Input() application: IApplication;
    @Input() denialReasonMapping: any;

    isEditing = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: PreliminaryHistoryDynamicConfig<IApplicationPreliminaryHistory>;
    doubleClickIsDisabled = false;

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: 'tab-medicalIssues',
        nextTabLabel: 'Medical Issues',
        previousTab: 'tab-basicsComplete',
        previousTabLabel: 'Basics Complete',
        routerLink: null,
        sectionId: FormSections.PreliminaryHistory,
    };

    constructor(
        private applicationService: ApplicationDataService,
        private notificationsService: NotificationsService,
        private prelimHistoryService: ApplicationPreliminaryHistoryService,
        private appService: ApplicationService,
        ) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new PreliminaryHistoryDynamicConfig<IApplicationPreliminaryHistory>(this.preliminaryHistory);
        this.config = this.formFactory.getForUpdate();
        this.applicationService.currentSection.next(this.currentSection);
    }
    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
        this.enableDoubleClick();
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.preliminaryHistory, form.value.ApplicationPreliminaryHistory);
            this.prelimHistoryService.update(this.preliminaryHistory).subscribe((appStatus: IApplicationValidationDTO) => {
                this.success(appStatus);
            });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
        this.enableDoubleClick();
    }

    success(appStatus: IApplicationValidationDTO): void {
        this.notificationsService.success('Preliminary history saved successfully.');
        if (appStatus.Denied) { this.notificationsService.info(`Donor has been denied. ${this.denialReasonMapping[appStatus.DenialId]}`); }
        this.setConfig();
        this.enableDoubleClick();
        this.isEditing = false;
        this.appService.emitChange(this.application);
    }
}
