import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';

@Injectable({ providedIn: 'root' })
export class ApplicationSsnService {
    constructor(private httpClient: HttpClient) {}

    getApplicationSsn(id: number): Observable<string> {
        return this.httpClient.get<string>(`/api/v1/application-ssn/${id}`);
    }

    updateApplicationSsn(id: number, data: IApplicationBasicInfo): Observable<IApplicationBasicInfo> {
        return this.httpClient.post<IApplicationBasicInfo>(`/api/v1/application-ssn/${id}`, data);
    }
}
