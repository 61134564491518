import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IDonor } from '@model/interfaces/donor';
import { DonorStatuses } from '@model/enums/donor-statuses.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormSections } from '@model/enums/form-sections.enum';
import { FormGroup } from '@angular/forms';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IApplication } from '@model/interfaces/application';

export const emptyDonor: IDonor = {
    Active: false,
    ApplicationId: 0,
    Approved: false,
    Archived: false,
    AvailableInCA: false,
    AvailableInAU: false,
    AvailableInUK: false,
    AvailableInOther: false,
    BabyBundle: false,
    DateCreated: new Date(),
    DonorBasicInfoId: 0,
    DonorFamilyHistoryId: 0,
    DonorId: 0,
    DonorIssueId: 0,
    DonorPreliminaryHistoryId: 0,
    DonorStatusId: DonorStatuses.Pending,
    DonorWhoAreYouId: 0,
    FamilyBundle: false,
    Id: 0,
    PremiumBankedEggs: false,
    BankedCustomChoice: false,
    Published: false,
    UserId: 0,
};

export interface ICurrentSection {
    sectionId: FormSections;
    previousTab: string;
    previousTabLabel: string;
    nextTab: string;
    nextTabLabel: string;
    formGroup: FormGroup;
    routerLink: string[];
}

@Injectable({
    providedIn: 'root',
})
export class ApplicationDataService extends BaseService<IApplication> {
    currentSection: BehaviorSubject<ICurrentSection> = new BehaviorSubject<ICurrentSection>({
        formGroup: null,
        nextTab: 'tab-preliminaryHistory',
        nextTabLabel: 'Preliminary History',
        previousTab: null,
        previousTabLabel: '',
        routerLink: null,
        sectionId: FormSections.Basicinfo,
    });

    constructor(public http: HttpClient) {
        super('/application', http);
    }

    getPersonalInformationMetaData(): Observable<IPersonalInformationMetaData> {
        return this.http.get<IPersonalInformationMetaData>('/options/personal-information');
    }

    adminSubmitApplication(applicationId: number): Observable<number> {
        return this.http.post<number>(`/applications/${applicationId}/admin-submit`, {});
    }
}
