import { IAddressContainer, IAddress } from '@model/interfaces/base';

export class AddressContainer {
    static getNew(): IAddressContainer {
        return {
            Address: {
                Address1: '',
                Address2: '',
                City: '',
                CountryCode: 'US',
                Id: 0,
                Province: '',
                StateCode: '',
                Zip: '',
            },
            AddressId: 0,
            IsPrimary: false,
        };
    }
    static buildAddressContainer(address: IAddress): IAddressContainer {
        return {
            Address: address,
            AddressId: address.Id,
            IsPrimary: false,
        };
    }
}
