import { Component, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBmiControlValue } from '@model/interfaces/custom/bmi-control-value';
import { CustomFormControlComponentBase, DynamicField } from '@mt-ng2/dynamic-form';

import { NumericControlTypes, round } from '@mt-ng2/numeric-control';

interface IFormValue {
    MeasurementTypeId: number;
    HeightFeet: number;
    HeightInches: number;
    HeightMetric: number;
    Weight: number;
}

@Component({
    styleUrls: ['./bmi-control.component.less', '../../override-bootstrap.less'],
    templateUrl: './bmi-control.component.html',
})
export class BmiControlComponent extends CustomFormControlComponentBase {
    wrapperForm: FormGroup;
    dateField: DynamicField;
    disabled = false;

    currentValue: IBmiControlValue;
    numericControlTypes = NumericControlTypes;

    constructor(changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();

        let value: IBmiControlValue;
        if (this.config.value) {
            value = JSON.parse(this.config.value as string);
        } else {
            value = {
                BMI: null,
                HeightFeet: null,
                HeightInches: null,
                HeightMetric: null,
                MeasurementTypeId: 1,
                Weight: null,
            };
        }

        this.currentValue = value;

        this.wrapperForm = this.formBuilder.group({
            HeightFeet: this.formBuilder.control(value.HeightFeet, [Validators.min(0)]),
            HeightInches: this.formBuilder.control(value.HeightInches, [Validators.min(0), Validators.max(12)]),
            HeightMetric: this.formBuilder.control(value.HeightMetric, [Validators.min(0)]),
            MeasurementTypeId: this.formBuilder.control(value.MeasurementTypeId),
            Weight: this.formBuilder.control(value.Weight, [Validators.min(0)]),
        });

        // wiring up the disabled property to propagate to our controls
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                const isDisabled = status === 'DISABLED';
                if (isDisabled && !this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.disable();
                    }, 0);
                    this.disabled = true;
                } else if (!isDisabled && this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.enable();
                    }, 0);
                    this.disabled = false;
                }
            }),
        );

        // subscribe to form value changes
        this.subscriptions.add(
            this.wrapperForm.valueChanges.subscribe((value) => {
                this.formValueChanged(value);
            }),
        );
    }

    formValueChanged(value: IFormValue): void {
        // tslint:disable-next-line: triple-equals
        const isImperial = value.MeasurementTypeId == 1;
        this.currentValue = {
            BMI: this.getBmi(value, isImperial),
            HeightFeet: isImperial ? value.HeightFeet : null,
            HeightInches: isImperial ? value.HeightInches : null,
            HeightMetric: isImperial ? null : value.HeightMetric,
            MeasurementTypeId: value.MeasurementTypeId,
            Weight: value.Weight,
        };
        this.getControl().patchValue(JSON.stringify(this.currentValue));
    }

    private getBmi(value: IFormValue, isImperial: boolean): number {
        let bmiValue: number;
        if (isImperial) {
            if (!(value.HeightFeet || value.HeightInches) || !value.Weight) {
                return null;
            }
            let heightInInches = value.HeightFeet * 12 + value.HeightInches;
            let heightInInchesSquared = heightInInches * heightInInches;
            bmiValue = (value.Weight / heightInInchesSquared) * 703;
        } else {
            if (!value.HeightMetric || !value.Weight) {
                return null;
            }
            let heightInMetricSquared = value.HeightMetric * value.HeightMetric;
            bmiValue = value.Weight / heightInMetricSquared;
        }
        return round(bmiValue, 1);
    }
}
